import {
  Box,
  Button,
  FormLabel,
  Image,
  Input,
  Link,
  Select,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { BsCheck, BsX } from "react-icons/bs";
import DeleteModal from "../components/common/Modal/DeleteModal";
import CreateModal from "../components/common/Modal/Modal";
import CreateTable from "../components/common/Table/Table";
import CreateSelect from "../components/common/TextInputs/CreateSelect";
import CreateInput from "../components/common/TextInputs/Input";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import Table from "../components/podcasts/Table";
import TableSeries from "../components/series/Table";
import useActions from "../hooks/useActions";
import { useNavigator } from "../hooks/useNavigator";
import {
  addPoster,
  createNewBanner,
  deleteBanner,
  deletePoster,
  getAllBanner,
  getAllBanners,
  getAllMovies,
  getAllSeries,
  getPoster,
  updateBanner,
  updateCategory,
  updatePoster,
  updateStatusBanner,
} from "../utils/api";

const Poster = () => {
  const [banner, setBanner] = React.useState([]);
  console.log(banner);
  const [update, setUpdate] = React.useState(false);
  const [newBanner, setNewBanner] = React.useState({});
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [openData, setOpenData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [updateAction, deleteAction] = useActions();
  const [itemData, setItemData] = React.useState([]);
  const [itemType, setItemType] = React.useState("");

  const bannersHandler = async () => {
    if (user?.getBanner) {
      const [success, data] = await getPoster();
      if (success) {
        setBanner(data);
      }
    }
    setLoading(false);
  };
  const getAllMovieHanler = async () => {
    const [success, data] = await getAllMovies();
    if (success) {
      setItemData(data);
    }
  };

  const getAllSeriesHandler = async () => {
    const [success, data] = await getAllSeries();
    if (success) {
      setItemData(data);
    }
  };
  // useEffect(() => {

  // }, [itemType]);

  const [navigate, user] = useNavigator(() => {
    if (user) {
      bannersHandler();
    } else {
      navigate("/login");
    }
  });
  const createBanner = async () => {
    if (
      newBanner.image
      //   &&
      //   ((newBanner.bannerType === "external" && newBanner.url) ||
      //     (newBanner.bannerType === "internal" && newBanner.id))
    ) {
      console.log(newBanner, update);
      setLoading(true);

      const formData = new FormData();
      formData.append("banner", newBanner.image);
      //   formData.append("bannerType", newBanner.bannerType);
      //   if (newBanner?.bannerType === "external") {
      //     formData.append("url", newBanner.url);
      //   } else {
      //     formData.append("type", itemType);
      //     formData.append("id", newBanner.id);
      //   }

      if (!update) {
        const [success, data] = await addPoster(formData);
        if (success) {
          toast({
            title: "Banner Created",
            description: "Banner Created Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          bannersHandler();
          onClose();
        } else {
          toast({
            title: "Banner Not Created",
            description: "Banner Not Created Successfully",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        console.log("update");
        const [success, data] = await updatePoster(formData, banner?._id);
        if (success) {
          toast({
            title: "Banner Updated",
            description: "Banner Updated Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setUpdate(false);
          onClose();
          bannersHandler();
        }
      }
    } else {
      toast({
        title: "fill all fields",
        description: "Banner Not Created Successfully",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      {!banner?.image && (
        <Button
          colorScheme="blue"
          variant="solid"
          onClick={() => {
            onOpen();
            setItemType("");
            setItemData({});
            setUpdate(false);
            setNewBanner({});
          }}
        >
          Create Poster
        </Button>
      )}

      <CreateTable
        data={banner?._id ? [banner] : []}
        head={["Image", "action"]}
        rows={(row) => {
          return (
            <>
              <Td>
                <Image
                  src={row?.image}
                  sx={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              </Td>

              {/* <Td>
                {row.link && (
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    onClick={() => {
                      onOpen2();
                      setOpenData(row);
                    }}
                  >
                    View {row?.link?.kind}
                  </Button>
                )}
              </Td> */}

              <Td>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 2,
                  }}
                >
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    onClick={() => {
                      setUpdate(true);
                      onOpen();
                    }}
                    sx={{
                      width: "100%",
                    }}
                  >
                    Edit
                  </Button>

                  <DeleteModal
                    onClick={async () => {
                      setLoading(true);
                      await deleteAction(row._id, deletePoster);
                      setLoading(false);
                      bannersHandler();
                    }}
                  />
                </Box>
              </Td>
            </>
          );
        }}
      />

      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={update ? "Update Banner" : "Create Banner"}
        create={createBanner}
      >
        <CreateInput
          name={"Image "}
          type="file"
          onChange={(e) => {
            setNewBanner((prev) => ({
              ...prev,
              image: e.target.files[0],
            }));
          }}
        />

        {/* <CreateSelect
          name={"Banner Type"}
          onChange={(e) => {
            setNewBanner((prev) => ({
              ...prev,
              bannerType: e.target.value,
            }));
          }}
          value={newBanner?.bannerType}
        >
          {" "}
          <option value="external">External</option>
          <option value="internal">Internal</option>
        </CreateSelect>
        {newBanner?.bannerType === "external" && (
          <CreateInput
            name={"Link"}
            onChange={(e) => {
              setNewBanner((prev) => ({
                ...prev,
                url: e.target.value,
              }));
            }}
            value={newBanner?.url}
          />
        )}
        {newBanner?.bannerType === "internal" && (
          <>
            <CreateSelect
              name={"Type"}
              onChange={(e) => {
                setItemType(e.target.value);
                if (e.target.value === "movie") {
                  getAllMovieHanler();
                } else if (e.target.value === "series") {
                  getAllSeriesHandler();
                }
              }}
              value={itemType}
            >
              <option value="movie">Movie</option>
              <option value="series">Series</option>
            </CreateSelect>

            {itemData.length > 0 && (
              <Select
                placeholder={`Select ${itemType}`}
                onChange={(e) => {
                  setNewBanner((prev) => ({
                    ...prev,
                    id: e.target.value,
                  }));
                }}
                value={newBanner?.id}
              >
                {itemData.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            )}
          </>
        )} */}
      </CreateModal>
      {openData && (
        <CreateModal title={"kind"} isOpen={isOpen2} onClose={onClose2} table>
          {openData?.link?.kind === "movie" ? (
            <Table allPodcasts={[openData?.link?.item]} />
          ) : (
            <TableSeries series={[openData?.link?.item]} />
          )}
        </CreateModal>
      )}
    </Box>
  );
};

export default Poster;
