import {
  Box,
  Button,
  Image,
  Select,
  Td,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import CreateTable from "../components/common/Table/Table";
import CreateTable2 from "../components/common/Table/PaginationTable";
import Loading from "../components/common/ui/Loading";
import Modal from "../components/users/Modal";
import { useNavigator } from "../hooks/useNavigator";
import {
  deleteUser,
  getAllSubscribtions,
  getAllUsers,
  getUserNotifications,
  getUserSubscriptions,
  getWalletHistory,
  updatePanDetails,
  updateUserSubscription,
} from "../utils/api";
import useActions from "../hooks/useActions";
import CreateModal from "../components/common/Modal/Modal";
import Fallback from "../components/common/ui/Fallback";
import UpdateWallet from "../components/users/UpdateWallet";
import DeleteModal from "../components/common/Modal/DeleteModal";
import BankDetailsModal from "../components/users/BankDetails";
import CreateInput from "../components/common/TextInputs/Input";
import AddToWallet from "../components/users/AddToWallet";
const Users = () => {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [updateUserWallet, setUpdateUserWallet] = React.useState({});
  const [walletHistory, setWalletHistory] = React.useState([]);

  const getWalletHistoryHandler = async (id) => {
    setLoading(true);
    const [success, data] = await getWalletHistory(id);

    if (success) {
      setWalletHistory(data.history);
    }
    setLoading(false);
  };
  const [notification, setNotification] = React.useState(false);

  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();

  const getNotification = async (id) => {
    setLoading(true);
    const [success, data] = await getUserNotifications(id);
    if (success) {
      setNotification(data);
      onOpenModal1();
    }

    setLoading(false);
  };
  const [BankDetails, setBankDetails] = React.useState(false);
  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();

  const {
    isOpen: isOpenModal3,
    onOpen: onOpenModal3,
    onClose: onCloseModal3,
  } = useDisclosure();

  const {
    isOpen: isOpenModal4,
    onOpen: onOpenModal4,
    onClose: onCloseModal4,
  } = useDisclosure();

  const {
    isOpen: isOpenModal5,
    onOpen: onOpenModal5,
    onClose: onCloseModal5,
  } = useDisclosure();

  const {
    isOpen: isOpenModal6,
    onOpen: onOpenModal6,
    onClose: onCloseModal6,
  } = useDisclosure();

  const {
    isOpen: isOpenModal7,
    onOpen: onOpenModal7,
    onClose: onCloseModal7,
  } = useDisclosure();

  const [pan, setPan] = React.useState({});
  const [subscription, setSubscription] = React.useState([]);
  const [allSubscriptions, setAllSubscriptions] = React.useState([]);
  const [userId, setUserId] = React.useState({});
  const getAllUsersHandler = async () => {
    if (user?.getUser) {
      const [success, data] = await getAllUsers();
      if (success) {
        setUsers(data);
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(getAllUsersHandler);
  const [updateAction, deleteAction] = useActions();
  const toast = useToast();
  const updatePanCardHandler = async () => {
    setLoading(true);
    if (pan.file === undefined || pan.file === null) {
      return alert("Please select a file");
    }
    const formData = new FormData();
    formData.append("image", pan.file);
    const [success, data] = await updatePanDetails(pan.id, formData);
    if (success) {
      toast({
        title: "Success",
        description: "Pan Card Updated",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      onCloseModal7();
    }
    setLoading(false);
  };

  const getUserSubscriptionsHandler = async (id) => {
    setLoading(true);
    const [success, data] = await getUserSubscriptions(id);
    if (success) {
      setSubscription(data);
      onOpenModal3();
    }
    setLoading(false);
  };

  const getAllSubsHandler = async () => {
    const [success, data] = await getAllSubscribtions();
    if (success) {
      setAllSubscriptions(data);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",

        flexDirection: "column",
        padding: "1rem",
      }}
    >
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <Loading />
        </div>
      )}
      {user?.addUser && (
        <Modal title={" Create User"} reload={getAllUsersHandler} />
      )}

      {user?.getUser ? (
        <CreateTable2
          pagging
          searchby={["name", "email", "phoneNumber"]}
          head={[
            "Subscription Actions",
            "Actions",
            "Profile",
            "Name",
            "Email",
            "Phone-Number",
            "Bank Account",
            "Total",
            "Referral",
            "Promotional",
            "Content Sharing",
            "App Sharing",
            "Reward Video",
            "Withdrawable",
            "Locked",
          ]}
          rows={(row) => {
            return (
              <>
                <Td>
                  <Box
                    sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                      }}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        getUserSubscriptionsHandler(row._id);
                      }}
                    >
                      Subscriptions
                    </Button>
                    <Button
                      sx={{
                        width: "100%",
                      }}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        getAllSubsHandler();
                        setUserId((prev) => ({ ...prev, userId: row._id }));
                        onOpenModal4();
                      }}
                    >
                      Add Subscription
                    </Button>
                    <Button
                      sx={{
                        width: "100%",
                      }}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        setUpdateUserWallet((prev) => ({
                          ...prev,
                          userId: row._id,
                          totalCoin: row.totalCoin,
                          referralCoin: row.referralCoin,
                          promotionalCoin: row.promotionalCoin,
                          appSharingCoin: row.appSharingCoin,
                          contentSharingCoin: row.contentSharingCoin,
                          rewardVideoCoin: row.rewardVideoCoin,
                          freeCoin: row.freeCoin,
                          lockedCoin: row.lockedCoin,
                        }));
                        onOpenModal5();
                      }}
                    >
                      Update User Wallet
                    </Button>
                    <Button
                      colorScheme={"blue"}
                      sx={{
                        width: "100%",
                      }}
                      variant="solid"
                      onClick={() => {
                        getWalletHistoryHandler(row._id);
                        onOpenModal6();
                      }}
                    >
                      Wallet History
                    </Button>
                    <Button
                      colorScheme={"blue"}
                      sx={{
                        width: "100%",
                      }}
                      variant="solid"
                      onClick={() => {
                        getNotification(row._id);
                      }}
                    >
                      Notifications
                    </Button>
                  </Box>
                </Td>
                <Td>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    {user?.updateUser && (
                      <>
                        <Modal
                          title={"Update User"}
                          reload={getAllUsersHandler}
                          updateData={row}
                          update={{
                            name: row.name,
                            email: row.email,
                            phoneNumber: row.phoneNumber,
                            image: row.image,
                            aadharNumber: row.aadharNumber,
                            ifsc: row.ifsc,
                            accountNumber: row.accountNumber,
                            bankName: row.bankName,
                            branchName: row.branchName,
                            upi: row.upi,
                            panNumber: row.panNumber,
                          }}
                        />
                      </>
                    )}
                    {user?.deleteUser && (
                      <DeleteModal
                        onClick={async () => {
                          setLoading(true);
                          await deleteAction(row._id, deleteUser);
                          getAllUsersHandler();
                          setLoading(false);
                        }}
                      />
                    )}
                    <Button
                      sx={{
                        width: "100%",
                      }}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        setPan({
                          id: row._id,
                        });
                        onOpenModal7();
                      }}
                    >
                      Update Pan Card
                    </Button>
                    <AddToWallet row={row} reload={getAllUsersHandler} />
                  </Box>
                </Td>
                <Td>
                  <Image
                    src={row.image}
                    rounded="full"
                    boxSize="50px"
                    loading="lazy"
                  />
                </Td>
                <Td>{row.name}</Td>
                <Td>{row.email}</Td>
                <Td>{row.phoneNumber}</Td>

                <Td>
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    onClick={() => {
                      setBankDetails(row ? row : "No Bank Details");
                      onOpenModal2();
                    }}
                  >
                    View
                  </Button>
                </Td>
                <Td>{row.totalCoin}</Td>
                <Td>{row.referralCoin}</Td>
                <Td>{row.promotionalCoin}</Td>
                <Td>{row.appSharingCoin}</Td>
                <Td>{row.contentSharingCoin}</Td>
                <Td>{row.rewardVideoCoin}</Td>
                <Td>{row.freeCoin}</Td>
                <Td>{row.lockedCoin}</Td>
              </>
            );
          }}
        />
      ) : (
        <Fallback />
      )}

      <CreateModal
        title={"Subscriptions"}
        isOpen={isOpenModal3}
        onClose={onCloseModal3}
        table
      >
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <Text sx={{ fontWeight: "700" }} size="lg">
            History
          </Text>
          {subscription?.map((sub, index) => (
            <Text sx={{ fontWeight: "600" }}>
              {index} : {sub.message}
            </Text>
          ))}
        </Box>
      </CreateModal>
      <CreateModal
        title={"Add Subscription"}
        isOpen={isOpenModal4}
        onClose={onCloseModal4}
        create={async () => {
          setLoading(true);
          await updateAction(userId, "id", updateUserSubscription);
          setUserId({});
          onCloseModal4();
          getAllUsersHandler();
          setLoading(false);
        }}
      >
        <Select
          placeholder="select subscription"
          onChange={(e) => {
            setUserId((prev) => ({ ...prev, subscriptionId: e.target.value }));
          }}
        >
          {allSubscriptions?.map((sub) => (
            <option value={sub._id}>{sub.title}</option>
          ))}
        </Select>
      </CreateModal>

      <UpdateWallet
        isOpenModal5={isOpenModal5}
        onCloseModal5={onCloseModal5}
        data={updateUserWallet}
        reload={getAllUsersHandler}
      />
      <CreateModal
        isOpen={isOpenModal6}
        onClose={onCloseModal6}
        title={"Wallet History"}
        table
      >
        <CreateTable
          data={walletHistory}
          head={["title", "balance", "type", "date"]}
          rows={(row) => (
            <>
              <Td>{row?.title}</Td>
              <Td>{row?.balance}</Td>
              <Td>{row?.type}</Td>
              <Td>{row?.date}</Td>
            </>
          )}
        />
      </CreateModal>
      <CreateModal
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        title={"Notification"}
        table
      >
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <CreateTable
            data={notification}
            head={["title", "description", "date", "image", "read"]}
            rows={(row) => (
              <>
                <Td>{row?.title}</Td>
                <Td>{row?.description.slice(0, 22)}</Td>
                <Td>{row?.date}</Td>
                <Td>
                  <Image src={row?.image} rounded="full" boxSize="50px" />
                </Td>
                <Td>{row?.read ? "true" : "false"}</Td>
              </>
            )}
          />
        </Box>
      </CreateModal>
      <BankDetailsModal
        open={isOpenModal2}
        onClose={onCloseModal2}
        BankDetails={BankDetails}
      />

      <CreateModal
        isOpen={isOpenModal7}
        onClose={onCloseModal7}
        title={"Edit Pan Card"}
        create={updatePanCardHandler}
      >
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <CreateInput
            type="file"
            onChange={(e) => {
              setPan((prev) => ({
                ...prev,
                file: e.target.files[0],
              }));
            }}
          />
          <Image
            src={pan?.file ? URL.createObjectURL(pan?.file) : pan?.image}
            rounded="full"
            boxSize="50px"
          />
        </Box>
      </CreateModal>
    </Box>
  );
};

export default Users;
