import { useEffect, useState } from "react";
import { Box, useToast, Button, Input, Text } from "@chakra-ui/react";
import Loading from "../components/common/ui/Loading";
import { useNavigator } from "../hooks/useNavigator";
import Fallback from "../components/common/ui/Fallback";
import CreateInput from "../components/common/TextInputs/Input";
import { addNotification, getAllUsers, userSearch } from "../utils/api";
import CheckboxInput from "../components/common/TextInputs/Checkbox";

const Notification = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [type, setType] = useState("ALL");

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filtered = users.filter((user) => {
      return user.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilteredUsers(filtered.slice(0, 100));
  };
  const AllUsers = async () => {
    setLoading2(true);
    const [success, data] = await userSearch({
      limit: 30,
      page: 1,
      searchType: "Name",
      searchQuery: search,
    });
    if (success) {
      setUsers(data?.users);
    }
    setLoading2(false);
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const addNewNotification = async () => {
    setLoading(true);
    if (title.length < 1) {
      toast({
        title: "Error",
        description: "Please Enter Title",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }
    if (description.length < 1) {
      toast({
        title: "Error",
        description: "Please Enter Description",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    const formdata = new FormData();
    formdata.append("title", title);
    formdata.append("description", description);
    formdata.append("image", image);
    formdata.append("type", type);
    if (type === "normal") {
      formdata.append(
        "users",
        JSON.stringify(selectedUsers.map((user) => user._id))
      );
    }

    const response = await addNotification(formdata);
    if (response[0]) {
      toast({
        title: "Success",
        description: "Notification Added",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setSelectedUsers([]);
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {
    if (user?.notification) {
    } else {
      // navigate("/login", {
      //   replace: true,
      // });
    }
  });
  useEffect(() => {
    AllUsers();
  }, [search]);

  if (loading) {
    return <Loading />;
  }

  return (
    <center>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
          padding: "1rem",
          maxWidth: "50%",
        }}
      >
        {user?.notification ? (
          <div>
            <br />
            <CreateInput
              name={"Title"}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <br />
            <CreateInput
              name={"Description"}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <br />
            <Text
              fontWeight="semibold"
              color="black"
              textAlign={"left"}
              marginBottom={".5rem"}
            >
              Select Image
            </Text>
            <Input type="file" onChange={(e) => setImage(e.target.files[0])} />
            <br />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: ".5rem",
                marginBottom: ".5rem",
                marginTop: ".5rem",
              }}
            >
              <h1
                style={{
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Users
              </h1>
              <CheckboxInput
                name={"Select All"}
                notification
                isChecked={type === "ALL"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setType("ALL");
                  } else {
                    setType("normal");
                  }
                }}
              />
            </Box>
            {type === "normal" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                  alignItems: "left",
                }}
              >
                {selectedUsers.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: ".5rem",
                      alignItems: "left",
                      padding: ".5rem",
                      backgroundColor: "white",
                      borderRadius: ".5rem",
                      border: "1px solid #e2e8f0",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "600",
                        color: "black",
                      }}
                    >
                      Selected Users
                    </h1>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: ".5rem",
                        flexWrap: "wrap",
                      }}
                    >
                      {selectedUsers.map((user) => {
                        return (
                          <Button
                            onClick={() => {
                              setSelectedUsers((prev) =>
                                prev.filter(
                                  (selectedUser) =>
                                    selectedUser._id !== user._id
                                )
                              );
                            }}
                            key={user?._id}
                            colorScheme={"blue"}
                          >
                            <Text>{user?.name}</Text>
                          </Button>
                        );
                      })}
                    </Box>
                  </Box>
                )}
                <CreateInput name={"Search"} onChange={handleSearch} />
                {users
                  ?.filter(
                    (user) =>
                      !selectedUsers.find(
                        (selectedUser) => selectedUser._id === user._id
                      )
                  )
                  ?.map((user) => {
                    return (
                      <CheckboxInput
                        name={user.name}
                        notification
                        isChecked={selectedUsers.includes(user._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedUsers((prev) => [...prev, user]);
                          } else {
                            setSelectedUsers((prev) =>
                              prev.filter((prev) => prev._id !== user._id)
                            );
                          }
                        }}
                      />
                    );
                  })}{" "}
              </Box>
            )}
            <br />
            <Button onClick={addNewNotification} colorScheme={"blue"}>
              Send Notification
            </Button>
          </div>
        ) : (
          <Fallback />
        )}
      </Box>
    </center>
  );
};

export default Notification;
