import axios from "axios";
const URL = "https://api.tangentweb.in";

export const loginEmail = async (username, password) => {
  try {
    const response = await axios.post(`${URL}/admin/login`, {
      email: username,
      password: password,
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const signup = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/signup`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getDashboardData = async () => {
  try {
    const res = await axios.get(`${URL}/admin/dashboard`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewSeries = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/series`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/category`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addSeriesToCategory = async (data) => {
  try {
    const config = {
      method: "put",
      url: `${URL}/admin/category/add-series`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios(config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addMovieToCategory = async (data) => {
  try {
    const config = {
      method: "put",
      url: `${URL}/admin/category/add-movie`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllBanner = async () => {
  try {
    const response = await axios.get(`${URL}/admin/banners`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllUsers = async (page) => {
  try {
    const response = await axios.get(
      `${URL}/admin/users?page=${page}&limit=20`
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const userSearch = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user-search`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const updateMovie = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/movie/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteMovie = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/movie/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }

    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateSeries = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/series/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteSeries = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/series/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateCategory = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/category/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/category/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateBanner = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/banner/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteBanner = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/banner/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/user/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateUserName = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateUserImage = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user-image/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllLiveVideos = async () => {
  try {
    const response = await axios.get(`${URL}/admin/live-videos`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewLiveVideo = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/live-video`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateLiveVideo = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/live-video/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteLiveVideo = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/live-video/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addSubscribtion = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllSubscribtions = async () => {
  try {
    const response = await axios.get(`${URL}/subscriptions`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const createNewBanner = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/banner`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllBanners = async () => {
  try {
    const response = await axios.get(`${URL}/banners`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllMovies = async () => {
  try {
    const response = await axios.get(`${URL}/movies`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllSeries = async () => {
  try {
    const response = await axios.get(`${URL}/series`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewMovie = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/movie`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllGeneres = async () => {
  try {
    const response = await axios.get(`${URL}/admin/genres`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${URL}/categories`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addGenre = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/genre`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateSubscription = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/subscription/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteSubscription = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/subscription/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addDataToSubcription = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subscription/add-video`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const removeFromSubscription = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/subscription/remove-video`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addNotification = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/notification`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const getAllAdmin = async () => {
  try {
    const response = await axios.get(`${URL}/admin/all-admin`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const updatePassword = async (data) => {
  try {
    const response = await axios.put(`${URL}/admin/update-password`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllUserRent = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/user-rent/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addDatatoRent = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user-add-rent`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const CheckPassword = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/check-password`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const removeSubscription = async (data) => {
  var config = {
    method: "put",
    url: `${URL}/admin/subscription/remove-video`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getUserSubscriptions = async (id) => {
  try {
    const response = await axios.get(
      `${URL}/admin/user-subscription-history/${id}`
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateUserSubscription = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user-subscription`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const removeMoviefromCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category/remove-movie`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const removeSeriesfromCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category/remove-series`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateUser = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateUserWalletData = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/update-wallet-balance`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getWalletHistory = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/user-wallet-history/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateSeriesPosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category-update/series-position`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updatePodcastPosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category-update/movie-position`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const systemValueUpdate = async (data) => {
  try {
    const response = await axios.put(`${URL}/admin/systemvalues`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getRedeemRequest = async () => {
  try {
    const response = await axios.get(`${URL}/admin/redeem-requests`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const acceptDenyRedeemRequest = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/redeem-request/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

// /user-notifications/:id

export const getUserNotifications = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/user-notifications/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updatePanDetails = async (id, data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/user-update-pancard/${id}`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const editAdminDetails = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/admin-update/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const enableDisableSeries = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/enable-series/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const subscribtionsCheckMovie = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/subscription-movie/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteAdmin = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/delete-admin/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const subscribtionsCheckSeries = async (id) => {
  try {
    const response = await axios.get(`${URL}/admin/subscription-series/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getUploadContentRequest = async () => {
  try {
    const response = await axios.get(`${URL}/admin/upload-content-request`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};
export const getSubCategories = async () => {
  try {
    const response = await axios.get(`${URL}/movie-subcategory`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};
export const getTangentCareerCategories = async () => {
  try {
    const response = await axios.get(`${URL}/contest-category`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};

export const rejectUploadContentRequest = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/reject-upload-content`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};
export const deleteUploadRequest = async (id) => {
  try {
    const response = await axios.put(`${URL}/admin/delete-upload-content`, {
      requestId: id,
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};

export const acceptUploadContentRequest = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/approve-upload-content`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};

export const editGenre = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/genre/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};

export const addPoster = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/singlebanner`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const addSubCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/moviesubcategory`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const addTangentCareerCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/contest-category`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const updateSubCategory = async (data, id) => {
  try {
    const response = await axios.put(
      `${URL}/admin/moviesubcategory/${id}`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const updateCareerCategory = async (data, id) => {
  try {
    const response = await axios.put(
      `${URL}/admin/contest-category/${id}`,
      data
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const deletePoster = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/singlebanner`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const deleteSubCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/moviesubcategory/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const deleteTangentCareerCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/contest-category/${id}`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const updatePoster = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/singlebanner`, data);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const getPoster = async () => {
  try {
    const response = await axios.get(`${URL}/single-banner`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const getAllcontest = async () => {
  try {
    const response = await axios.get(`${URL}/admin/contests`);
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};

export const getPurchaseRecords = async (page) => {
  try {
    const response = await axios.get(
      `${URL}/admin/purchase-records?page=${page}`
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
export const getChainRecords = async (page) => {
  try {
    const response = await axios.get(
      `${URL}/admin/referral-chains?page=${page}`
    );
    if (response.status === 200) return [true, response.data];
    else return [false, response.data];
  } catch (err) {
    console.log(err);
    return [false, err];
  }
};
