import React, { useEffect } from "react";
import { Box, Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import CheckboxInput from "../common/TextInputs/Checkbox";
const Modal = ({ save, title, update, color, data }) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [newPodcast, setNewPodcast] = React.useState({
    email: "",
    password: "",
  });

  const saveHandler = async () => {
    if (newPodcast.email && newPodcast.password) {
      const newPodcastData = {
        ...newPodcast,
      };
      await save(newPodcastData);
      onClose();
    } else {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const clickCheckbox = (e, name) => {
    const { checked } = e.target;
    setNewPodcast((prev) => ({ ...prev, [name]: checked }));
  };

  useEffect(() => {
    if (data) {
      data.map((item) => {
        setNewPodcast((prev) => ({ ...prev, [item]: false }));
      });
    }
  }, [data]);

  return (
    <Box
      sx={{
        gap: 3,
        width: "100%",
      }}
    >
      <Button
        onClick={onOpen}
        leftIcon={!color && <BsPlus />}
        colorScheme={color ? color : "blue"}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={saveHandler}
      >
        <CreateInput
          name="Email"
          onChange={(e) => {
            setNewPodcast((prev) => ({ ...prev, email: e.target.value }));
          }}
        />
        <CreateInput
          name="Password"
          onChange={(e) => {
            setNewPodcast((prev) => ({ ...prev, password: e.target.value }));
          }}
        />
        <Box
          fontSize="md"
          fontWeight={"bold"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          Privages{" "}
          <CheckboxInput
            name={"all"}
            onChange={(e) => {
              const { checked } = e.target;
              data.map((item) => {
                setNewPodcast((prev) => ({ ...prev, [item]: checked }));
              });
            }}
          />
        </Box>
        {data.map((item) => (
          <CheckboxInput
            key={item}
            name={item}
            onChange={(e) => clickCheckbox(e, item)}
            isChecked={newPodcast[item]}
          />
        ))}
      </CreateModal>
    </Box>
  );
};

export default Modal;
