import {
  Box,
  Button,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { getAllUsers, userSearch } from "../../../utils/api";
import useStore from "../../../utils/store";

const CreateTable = ({ head, rows, pagging, searchby, subcategories }) => {
  const [page, setPage] = React.useState(1);
  const [isLoading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const data = users?.users;
  const [limit, setLimit] = React.useState(20);
  const [total, setTotal] = React.useState(
    data?.users > 0 ? users?.totalData : 0
  );
  const [first, setFirst] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [sort, setSort] = React.useState(
    searchby && searchby?.length > 0 ? searchby[0] : ""
  );
  const user = useStore((state) => state.user);
  const [searchData, setSearchData] = React.useState(data);
  const getAllUsersHandler = async (reset) => {
    setLoading(true);
    if (user?.getUser) {
      if (sort?.trim() === "" || search?.trim() === "" || reset) {
        const [success, data] = await getAllUsers(page);
        if (success) {
          setUsers(data);
          setTotal(data?.totalData);
        }
      } else {
        const [success, data] = await userSearch({
          limit: limit,
          page: page,
          searchType: sort.charAt(0).toUpperCase() + sort.slice(1),
          searchQuery: search,
        });
        if (success) {
          console.log(data);
          setFirst(false);
          setUsers(data);
          setTotal(data?.totalData ?? 0);
        }
      }
    }
    setLoading(false);
  };

  const searchOnChange = (e) => {
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    getAllUsersHandler();
  }, [page]);

  return (
    <Box>
      {searchby && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 3,
            marginBottom: 3,
            marginTop: 3,
          }}
        >
          {" "}
          <Select
            placeholder="Sort"
            onChange={(e) => setSort(e.target.value)}
            value={sort}
            disabled={isLoading}
          >
            {searchby?.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
          <Input
            placeholder="Search"
            onChange={(e) => searchOnChange(e)}
            value={search}
            // disabled={isLoading}
          />
          <Button
            isLoading={isLoading}
            variant={"solid"}
            sx={{
              padding: "6px 20px",
            }}
            onClick={() => {
              setPage(1);
              getAllUsersHandler();
            }}
            colorScheme={"blue"}
          >
            Search
          </Button>
          <Button
            isLoading={isLoading}
            variant={"solid"}
            sx={{
              padding: "6px 20px",
            }}
            onClick={() => {
              setPage(1);
              setSearch("");
              getAllUsersHandler(true);
            }}
            colorScheme={"blue"}
          >
            Reset
          </Button>
        </Box>
      )}

      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>{head?.map((item) => item && <Th key={item}>{item}</Th>)}</Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Th colSpan={head?.length}>Loading...</Th>
              </Tr>
            ) : data?.length === 0 ? (
              <Tr>
                <Th>No data found</Th>
              </Tr>
            ) : null}
            {data?.map((row, index) => (
              <Tr key={index}>{rows(row)}</Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>{head?.map((item) => item && <Th key={item}>{item}</Th>)}</Tr>
          </Tfoot>
        </Table>
      </TableContainer>

      {pagging && (
        <>
          <Text
            fontSize="sm"
            color="gray.500"
            sx={{
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            {`Showing ${page * limit - limit + 1} to ${
              page * limit > total ? total : page * limit
            } of ${total} entries`}
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 3,

              paddingBottom: 5,
              overflow: "auto",
            }}
          >
            {new Array(Math?.ceil(total / limit))
              ?.fill(0)
              ?.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => setPage(index + 1)}
                  colorScheme={page === index + 1 ? "blue" : "gray"}
                  variant="solid"
                >
                  {index + 1}
                </Button>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreateTable;
