import {
  Box,
  Button,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

const CreateTable = ({
  head,
  rows,
  data,
  pagging,
  searchby,
  subcategories,
}) => {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [total, setTotal] = React.useState(data?.length > 0 ? data?.length : 0);
  const [search, setSearch] = React.useState("");
  const [sort, setSort] = React.useState(
    searchby && searchby?.length > 0 ? searchby[0] : ""
  );
  const [searchData, setSearchData] = React.useState(data);

  const searchOnChange = (e) => {
    setSearch(e.target.value);

    if (e.target.value === "") {
      setSearchData(data);
      setTotal(data?.length);
      setPage(1);
    }

    const res = data?.filter((item) => {
      return item?.[sort]
        ?.toLowerCase()
        .includes(e.target.value?.toLowerCase());
    });
    setTotal(res?.length);
    setPage(1);
    setSearchData(res);
  };

  // React.useEffect(() => {
  //   if (search === "") {
  //     setSearchData(data);
  //     setTotal(data?.length);
  //     setPage(1);
  //   } else {
  //     const res = searchData?.filter((item) => {
  //       return item?.[sort]?.toLowerCase().includes(search?.toLowerCase());
  //     });
  //     setTotal(res?.length);
  //     setPage(1);
  //     setSearchData(res);
  //   }
  // }, [search, sort]);

  return (
    <Box>
      {searchby && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 3,
            marginBottom: 3,
            marginTop: 3,
          }}
        >
          {" "}
          <Select
            placeholder="Sort"
            onChange={(e) => setSort(e.target.value)}
            value={sort}
          >
            {searchby?.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
          {sort && sort === "subcategory" ? (
            <Select placeholder="Search" onChange={(e) => searchOnChange(e)}>
              {subcategories?.map((item) => (
                <option key={item?.title} value={item?.title}>
                  {item?.title}
                </option>
              ))}
            </Select>
          ) : (
            <Input placeholder="Search" onChange={(e) => searchOnChange(e)} />
          )}
        </Box>
      )}

      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>{head?.map((item) => item && <Th key={item}>{item}</Th>)}</Tr>
          </Thead>
          <Tbody>
            {searchby
              ? searchData
                  ?.slice((page - 1) * limit, page * limit)
                  ?.map((row, index) => <Tr key={index}>{rows(row)}</Tr>)
              : data?.map((row, index) => <Tr key={index}>{rows(row)}</Tr>)}
          </Tbody>
          <Tfoot>
            <Tr>{head?.map((item) => item && <Th key={item}>{item}</Th>)}</Tr>
          </Tfoot>
        </Table>
      </TableContainer>

      {pagging && (
        <>
          <Text
            fontSize="sm"
            color="gray.500"
            sx={{
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            {`Showing ${page * limit - limit + 1} to ${
              page * limit > total ? total : page * limit
            } of ${total} entries`}
          </Text>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 3,

              paddingBottom: 5,
              overflow: "auto",
            }}
          >
            {new Array(Math?.ceil(total / limit))
              ?.fill(0)
              ?.map((item, index) => (
                <Button
                  key={index}
                  onClick={() => setPage(index + 1)}
                  colorScheme={page === index + 1 ? "blue" : "gray"}
                  variant="solid"
                >
                  {index + 1}
                </Button>
              ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreateTable;
