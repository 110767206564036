import { Image, Td, Link, Button, Box } from "@chakra-ui/react";
import React from "react";
import useActions from "../../hooks/useActions";
import { deleteLiveVideo, updateLiveVideo } from "../../utils/api";
import useStore from "../../utils/store";
import CreateTable from "../common/Table/Table";
import Loading from "../common/ui/Loading";
import Modal from "./Modal";

const Table = ({ allPodcasts, series, actions, reload }) => {
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const actionsI =
    actions && (user?.updateLiveVideo || user?.deleteLiveVideo)
      ? "Actions"
      : false;
  const [updateActionHandler, deleteAction] = useActions();
  if (loading) {
    return <Loading />;
  }
  return (
    <CreateTable
      head={[
        actionsI,
        "Type",
        "Name",

        "Description",
        "Url",
        "Horizontal-Banner",
        "Vertical-Banner",
        "Duration",

        "Rating",
        "Language",
        "Age-Limit",

        "Genre",
      ]}
      data={allPodcasts}
      rows={(item) => {
        return (
          <>
            {actions && (user?.updateLiveVideo || user?.deleteLiveVideo) && (
              <Td>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: ".5rem",
                  }}
                >
                  {user?.updateLiveVideo && (
                    <Modal
                      title={"Edit"}
                      update={item}
                      color="green"
                      save={async (data, id) => {
                        setLoading(true);
                        await updateActionHandler(data, id, updateLiveVideo);
                        reload();
                        setLoading(false);
                      }}
                    />
                  )}
                  {user?.deleteLiveVideo && (
                    <Button
                      colorScheme="red"
                      onClick={async () => {
                        setLoading(true);
                        await deleteAction(item._id, deleteLiveVideo);
                        reload();
                        setLoading(false);
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </Box>
              </Td>
            )}
            <Td>{item.type}</Td>
            <Td>{item.name}</Td>
            <Td>{item.description.slice(0, 25)}...</Td>
            <Td>
              <Link
                href={item.url}
                isExternal
                sx={{
                  color: "blue.500",
                  textDecoration: "underline",
                }}
              >
                Link
              </Link>
            </Td>
            <Td>
              <Image
                src={item.horizontalBanner}
                alt="horizontal-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>
            <Td>
              <Image
                src={item.verticalBanner}
                alt="vertical-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>
            <Td>{item.duration}</Td>
            <Td>{item.rating}</Td>
            <Td>{item.language}</Td>
            <Td>{item.ageLimit}</Td>

            <Td>
              {item.genres.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </Td>
          </>
        );
      }}
    />
  );
};

export default Table;
