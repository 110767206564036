import { Box, useToast } from "@chakra-ui/react";
import React from "react";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import Modal from "../components/liveVideo/Modal";
import Table from "../components/liveVideo/Table";

import { useNavigator } from "../hooks/useNavigator";
import { createNewLiveVideo, getAllLiveVideos } from "../utils/api";

const Live = () => {
  const [liveVideo, setLiveVideo] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const toast = useToast();
  const getAllLiveVideoHandler = async () => {
    if (user?.getLiveVideo) {
      const [success, data] = await getAllLiveVideos();
      if (success) {
        setLiveVideo(data);
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(getAllLiveVideoHandler);

  const createNewLiveVideoHandler = async (newLiveVideo, id) => {
    setLoading(true);
    const [success, res] = await createNewLiveVideo(newLiveVideo);
    if (success) {
      toast({
        title: "Live Video Created",
        description: "Live Video Created Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      getAllLiveVideoHandler();
    } else {
      toast({
        title: "Error",
        description: "Error Creating Live Video",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        padding: "1rem",
      }}
    >
      {user?.addLiveVideo && (
        <Modal save={createNewLiveVideoHandler} title="Create Live Video" />
      )}
      {user?.getLiveVideo ? (
        <Table
          allPodcasts={liveVideo}
          actions
          reload={getAllLiveVideoHandler}
        />
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default Live;
