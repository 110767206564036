import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import { getAllGeneres, getSubCategories } from "../../utils/api";
import CheckboxInput from "../common/TextInputs/Checkbox";
import { Link } from "react-router-dom";
const Modal = ({
  save,
  title,
  series,
  update,
  color,
  episeries,
  categories,
  buttonText,
}) => {
  const toast = useToast();
  const [newCast, setNewCast] = React.useState({
    name: "",
    image: "",
    role: "",
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [genre, setGenre] = React.useState([]);
  const [allGenre, setAllGenre] = React.useState([]);
  const [newPodcast, setNewPodcast] = React.useState({});
  const [cast, setCast] = React.useState([]);
  const podcastDataChage = (e, name) => {
    const { value } = e.target;
    setNewPodcast((prev) => ({ ...prev, [name]: value }));
  };

  const durationCovertion = (duration) => {
    let time = duration;
    let sp = time?.split(" ");
    let hr = 0;
    let min = 0;
    let sec = 0;

    for (let i = 0; i < sp?.length; i++) {
      if (sp[i]?.includes("hr")) {
        const textArray = sp[i]?.split("hr");
        hr = Number(textArray?.shift());
      } else if (sp[i]?.includes("min")) {
        const textArray = sp[i]?.split("min");
        min = Number(textArray?.shift());
      } else {
        const textArray = sp[i]?.split("sec");
        sec = Number(textArray?.shift());
      }
    }

    return {
      hr,
      min,
      sec,
    };
  };
  const getAllGeneresHandler = async () => {
    const [success, data] = await getAllGeneres();
    if (success) {
      setAllGenre(data);
    }
  };

  useEffect(() => {
    if (update) {
      setNewPodcast({
        name: update?.name,
        url: update?.url,
        urlType: update?.urlType,
        description: update?.description,
        verticalBanner: update?.verticalBanner,
        horizontalBanner: update?.horizontalBanner,
        duration: durationCovertion(update?.duration),
        language: update?.language,
        rating: update?.rating,
        ageLimit: update?.ageLimit,
        trailer: update?.trailer,
        trailerType: update?.trailerType,
        promotionalLink: update?.promotionalLink,
        extraCast: update?.extraCast,
        subcategory: update?.subcategory,
      });
      setGenre(update?.genres);
      setCast(update?.cast);
    }
  }, [update]);
  const saveHandler = async () => {
    if (
      newPodcast?.name &&
      newPodcast?.description &&
      newPodcast?.verticalBanner &&
      newPodcast?.horizontalBanner &&
      (series || newPodcast?.duration) &&
      (series || episeries || newPodcast?.subcategory) &&
      (episeries || newPodcast?.language) &&
      (episeries || newPodcast?.rating) &&
      (episeries || newPodcast?.ageLimit) &&
      (episeries || genre?.length > 0)
    ) {
      let duration = "";
      if (+newPodcast?.duration?.hr && +newPodcast?.duration?.hr !== 0) {
        duration = duration + newPodcast?.duration?.hr + "hr ";
      }
      if (+newPodcast?.duration?.min && +newPodcast?.duration?.min !== 0) {
        duration = duration + newPodcast?.duration?.min + "min ";
      }
      if (+newPodcast?.duration?.sec && +newPodcast?.duration?.sec !== 0) {
        duration = duration + newPodcast?.duration?.sec + "sec";
      }
      const newPodcastData = {
        ...newPodcast,
        duration: duration,

        genres: genre,
        cast: cast,
      };
      if (!update) {
        setNewPodcast((prev) => ({
          ...prev,
          name: "",
          description: "",
          verticalBanner: "",
          horizontalBanner: "",
          url: "",
          urlType: "",
          trailerType: "",
          subcategory: "",
          duration: {
            hr: 0,
            min: 0,
            sec: 0,
          },
          language: "",
          rating: "",
          ageLimit: "",
          trailer: "",
          promotionalLink: "",
          extraCast: "",
        }));
      }
      await save(newPodcastData, update?._id);
      onClose();
    } else {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      sx={{
        gap: 3,
        width: "100%",
      }}
    >
      <Button
        onClick={() => {
          getAllGeneresHandler();
          onOpen();
        }}
        sx={{
          width: "100%",
        }}
        leftIcon={!color && <BsPlus />}
        colorScheme={color ? color : "blue"}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={saveHandler}
        button1={buttonText}
      >
        <CreateInput
          name="Name"
          onChange={(e) => podcastDataChage(e, "name")}
          value={newPodcast?.name ? newPodcast.name : ""}
        />
        <CreateInput
          name="Description"
          onChange={(e) => {
            podcastDataChage(e, "description");
          }}
          value={newPodcast?.description ? newPodcast.description : ""}
        />
        {!series && (
          <>
            <Text fontSize={"md"} fontWeight={"semibold"}>
              Url-Type
            </Text>
            <Select
              placeholder="Select Url-Type"
              value={newPodcast?.urlType ? newPodcast.urlType : ""}
              onChange={(e) => {
                setNewPodcast((prev) => ({
                  ...prev,
                  urlType: e.target.value,
                }));
              }}
            >
              <option value="Youtube">Youtube</option>
              <option value="Video">Video</option>
            </Select>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "row",
                alignItems: "end",
              }}
            >
              <CreateInput
                name="Url"
                onChange={(e) => {
                  podcastDataChage(e, "url");
                }}
                value={newPodcast?.url ? newPodcast.url : ""}
              />
              {update && (
                <Link to={"/player"} state={{ url: newPodcast.url }}>
                  <Button colorScheme="blue">Preview</Button>
                </Link>
              )}
            </Box>
          </>
        )}

        {!episeries && !series && (
          <>
            {" "}
            <Text fontSize={"md"} fontWeight={"semibold"}>
              Sub Category
            </Text>
            <Select
              placeholder="Select Sub Category"
              value={newPodcast?.subcategory ? newPodcast.subcategory : ""}
              onChange={(e) => {
                setNewPodcast((prev) => ({
                  ...prev,
                  subcategory: e.target.value,
                }));
              }}
            >
              {categories?.map((item, index) => (
                <option key={index} value={item.title}>
                  {item.title}
                </option>
              ))}
            </Select>
          </>
        )}
        {!episeries && (
          <>
            <Text fontSize={"md"} fontWeight={"semibold"}>
              Trailer-Type
            </Text>
            <Select
              placeholder="Select Trailer-Type"
              value={newPodcast?.trailerType ? newPodcast.trailerType : ""}
              onChange={(e) => {
                setNewPodcast((prev) => ({
                  ...prev,
                  trailerType: e.target.value,
                }));
              }}
            >
              <option value="Youtube">Youtube</option>
              <option value="Video">Video</option>
            </Select>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "row",
            alignItems: "end",
          }}
        >
          <CreateInput
            name={"Horizontal-Banner"}
            onChange={(e) => {
              podcastDataChage(e, "horizontalBanner");
            }}
            value={
              newPodcast?.horizontalBanner ? newPodcast.horizontalBanner : ""
            }
          />
          {update && (
            <a href={newPodcast?.horizontalBanner} target="_blank">
              <Button colorScheme="blue">Preview</Button>
            </a>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "row",
            alignItems: "end",
          }}
        >
          <CreateInput
            name={"Vertical-Banner"}
            onChange={(e) => {
              podcastDataChage(e, "verticalBanner");
            }}
            value={newPodcast?.verticalBanner ? newPodcast.verticalBanner : ""}
          />
          {update && (
            <a href={newPodcast?.verticalBanner} target="_blank">
              <Button colorScheme="blue">Preview</Button>
            </a>
          )}
        </Box>
        {!series && (
          <Box sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
            <Text fontSize={"md"} fontWeight={"semibold"}>
              Duration
            </Text>
            <Box sx={{ display: "flex", gap: "0.7rem" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Select
                  value={newPodcast?.duration?.hr}
                  onChange={(e) => {
                    setNewPodcast((prev) => ({
                      ...prev,
                      duration: {
                        ...prev.duration,
                        hr: e.target.value,
                      },
                    }));
                  }}
                >
                  {Array.from(Array(25).keys()).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <Text fontSize={"md"}>hr</Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Select
                  value={newPodcast?.duration?.min}
                  onChange={(e) => {
                    setNewPodcast((prev) => ({
                      ...prev,
                      duration: {
                        ...prev.duration,
                        min: e.target.value,
                      },
                    }));
                  }}
                >
                  {Array.from(Array(61).keys()).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                <Text fontSize={"md"}>min</Text>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Select
                  value={newPodcast?.duration?.sec}
                  onChange={(e) => {
                    setNewPodcast((prev) => ({
                      ...prev,
                      duration: {
                        ...prev.duration,
                        sec: e.target.value,
                      },
                    }));
                  }}
                >
                  {Array.from(Array(61).keys()).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>

                <Text fontSize={"md"}>sec</Text>
              </Box>
            </Box>
          </Box>
        )}

        {!episeries && (
          <>
            <CreateInput
              name="Promotional Link"
              onChange={(e) => {
                podcastDataChage(e, "promotionalLink");
              }}
              value={
                newPodcast?.promotionalLink ? newPodcast.promotionalLink : ""
              }
            />
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "row",
                alignItems: "end",
              }}
            >
              <CreateInput
                name={"Trailer"}
                onChange={(e) => {
                  podcastDataChage(e, "trailer");
                }}
                value={newPodcast?.trailer ? newPodcast.trailer : ""}
              />
              {update && (
                <Link to={"/player"} state={{ url: newPodcast.trailer }}>
                  <Button colorScheme="blue">Preview</Button>
                </Link>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem 0rem",
                gap: ".5rem",
              }}
            >
              <FormLabel size={"sm"}>Genre</FormLabel>
              {genre?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                    backgroundColor: "#eee",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {genre.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text>{item.name ? item.name : item}</Text>
                      <Text
                        sx={{
                          color: "red.500",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const newGenre = genre.filter(
                            (item, i) => i !== index
                          );
                          setGenre(newGenre);
                        }}
                      >
                        Remove
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
              {/* <InputGroup size="md">
            <Input
              pr="4.5rem"
              placeholder="Add"
              value={newGenre}
              onChange={(e) => {
                setNewGenre(e.target.value);
              }}
            />
            <InputRightElement width="7rem">
              <Button
                size="sm"
                onClick={() => {
                  setGenre((prev) => [...prev, newGenre]);
                  setNewGenre("");
                }}
              >
                Create Genre
              </Button>
            </InputRightElement>
          </InputGroup> */}

              {allGenre?.length > 0 &&
                allGenre.map((item, index) => (
                  <CheckboxInput
                    name={item.name}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const res = genre || [];

                        // setGenre((prev) => [...prev, item._id]);
                        res.push(item._id);
                        setGenre(res);
                      } else {
                        const newGenre = genre.filter(
                          (items) => items !== item._id
                        );
                        setGenre(newGenre);
                      }
                    }}
                  />
                ))}
            </Box>
            <CreateInput
              name="Rating"
              onChange={(e) => {
                setNewPodcast((prev) => {
                  return {
                    ...prev,
                    rating: e.target.value,
                  };
                });
              }}
              value={newPodcast?.rating ? newPodcast.rating : ""}
            />
            <CreateInput
              name="Language"
              onChange={(e) => {
                setNewPodcast((prev) => {
                  return {
                    ...prev,
                    language: e.target.value,
                  };
                });
              }}
              value={newPodcast?.language ? newPodcast.language : ""}
            />
            <CreateInput
              name="Age-Limit"
              onChange={(e) => {
                setNewPodcast((prev) => {
                  return {
                    ...prev,
                    ageLimit: e.target.value,
                  };
                });
              }}
              value={newPodcast?.ageLimit ? newPodcast.ageLimit : ""}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem 0rem",
                gap: ".5rem",
              }}
            >
              <FormLabel size={"sm"}>Cast</FormLabel>
              {cast?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column",
                    backgroundColor: "#eee",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                  }}
                >
                  {cast.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      <Text>{item.name}</Text>
                      <Text
                        sx={{ color: "red.500", cursor: "pointer" }}
                        onClick={() => {
                          const newCast = cast.filter((item, i) => i !== index);
                          setCast(newCast);
                        }}
                      >
                        Remove
                      </Text>
                    </Box>
                  ))}
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                  border: "1px solid #eee",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <Input
                  placeholder="Name"
                  onChange={(e) => {
                    setNewCast((prev) => ({ ...prev, name: e.target.value }));
                  }}
                  value={newCast?.name}
                />
                <Input
                  placeholder="Image-Url"
                  onChange={(e) => {
                    setNewCast((prev) => ({ ...prev, image: e.target.value }));
                  }}
                  value={newCast?.image}
                />
                <Input
                  placeholder="Role"
                  onChange={(e) => {
                    setNewCast((prev) => ({ ...prev, role: e.target.value }));
                  }}
                  value={newCast?.role}
                />
                <Button
                  size="sm"
                  onClick={() => {
                    const newCasts = cast || [];

                    newCasts.push(newCast);
                    setCast(newCasts);
                    setNewCast({
                      name: "",
                      image: "",
                      role: "",
                    });
                  }}
                >
                  Create Cast
                </Button>
              </Box>
              {!episeries && (
                <Input
                  placeholder="Supporting Actor/Actress"
                  onChange={(e) => {
                    setNewPodcast((prev) => {
                      return {
                        ...prev,
                        extraCast: e.target.value,
                      };
                    });
                  }}
                  value={newPodcast?.extraCast ? newPodcast.extraCast : ""}
                />
              )}
            </Box>
          </>
        )}
      </CreateModal>
    </Box>
  );
};

export default Modal;
