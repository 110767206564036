import { Button, Td } from "@chakra-ui/react";
import React from "react";
import { CSVLink } from "react-csv";
import CreateTable from "../components/common/Table/Table";
import { getAllcontest } from "../utils/api";

const IndianIcon = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getAllData = async () => {
    const [success, data] = await getAllcontest();
    if (success) {
      setData(data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            marginBlock: "20px",
          }}
        >
          Tangent Career
        </h1>
        <CSVLink
          filename={`Tangent-Career-${
            new Date().toISOString().split("T")[0]
          }.csv`}
          data={data?.map((item) => {
            return {
              Name: item?.name,
              Token: item?.ticket,
              "Phone Number": item?.phoneNumber?.toString(),
              Gender: item?.gender,
              DOB: item?.dob?.toString(),
              Category: item?.category,
            };
          })}
          target="_blank"
        >
          <Button colorScheme="blue">Download</Button>
        </CSVLink>
      </div>
      <CreateTable
        data={data}
        head={["Name", "Token", "Phone Number", "Gender", "DOB", "Category"]}
        rows={(row) => {
          return (
            <>
              <Td>{row.name}</Td>
              <Td>{row.ticket}</Td>
              <Td>{row.phoneNumber}</Td>
              <Td>{row?.gender}</Td>
              <Td>{row?.dob}</Td>
              <Td>{row?.category}</Td>
            </>
          );
        }}
      />
    </>
  );
};

export default IndianIcon;
