import { Box, useToast } from "@chakra-ui/react";
import React from "react";
import Modal from "../components/Catagories/Modal";
import TableCategories from "../components/Catagories/Table";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import { useNavigator } from "../hooks/useNavigator";
import { getAllCategories, getAllMovies, getAllSeries } from "../utils/api";

const Categories = () => {
  const [categories, setCategories] = React.useState([]);

  const [movie, setMovie] = React.useState([]);
  const [series, setSeries] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const getAllCategoriesHandler = async () => {
    setLoading(true);
    if (user?.getCategory) {
      const [success, data] = await getAllCategories();
      const [success2, data2] = await getAllMovies();
      const [success3, data3] = await getAllSeries();
      if (success) {
        setCategories(data);
        setMovie(data2);
        setSeries(data3);
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(getAllCategoriesHandler);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          gap: "1rem",
        }}
      >
        {user?.addCategory && (
          <Modal reload={getAllCategoriesHandler} title={"Create Category"} />
        )}
        {user?.getCategory ? (
          <TableCategories
            categories={categories}
            movie={movie}
            series={series}
            reload={getAllCategoriesHandler}
          />
        ) : (
          <Fallback />
        )}
      </Box>
    </>
  );
};

export default Categories;
