import React, { useEffect } from "react";
import useActions from "../../hooks/useActions";
import { updateUserWalletData } from "../../utils/api";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import Loading from "../common/ui/Loading";
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Toast,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";

const coins = [
  "totalCoin",
  "referralCoin",
  "promotionalCoin",
  "appSharingCoin",
  "contentSharingCoin",
  "rewardVideoCoin",
  "freeCoin",
  "lockedCoin",
];

const AddToWallet = ({ row, reload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [updateUserWallet, setUpdateUserWallet] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [updateAction, deleteAction] = useActions();
  const toast = useToast();
  console.log(row);
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Button
        sx={{
          width: "100%",
        }}
        colorScheme="blue"
        variant="solid"
        onClick={onOpen}
      >
        Wallet
      </Button>
      <CreateModal
        title={"Add to Wallet"}
        isOpen={isOpen}
        onClose={onClose}
        create={async () => {
          if (
            !updateUserWallet.amount ||
            !updateUserWallet.type ||
            !updateUserWallet.message
          ) {
            return toast({
              title: "Error",
              description: "Please fill all fields",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
          setLoading(true);

          let data = {};

          if (updateUserWallet.type === "totalCoin") {
            data = {
              totalCoin: row.totalCoin + parseInt(updateUserWallet.amount),
            };
          }
          if (
            coins.findIndex((coin) => coin === updateUserWallet.type) > 0 &&
            coins.findIndex((coin) => coin === updateUserWallet.type) < 6
          ) {
            data = {
              [updateUserWallet.type]:
                row[updateUserWallet.type] + parseInt(updateUserWallet.amount),
              totalCoin: row.totalCoin + parseInt(updateUserWallet.amount),
              freeCoin: row.freeCoin + parseInt(updateUserWallet.amount),
            };
          }

          if (
            updateUserWallet.type === "lockedCoin" ||
            updateUserWallet.type === "freeCoin"
          ) {
            data = {
              [updateUserWallet.type]:
                row[updateUserWallet.type] + parseInt(updateUserWallet.amount),
              totalCoin: row.totalCoin + parseInt(updateUserWallet.amount),
            };
          }

          await updateAction(
            {
              userId: row._id,
              ...data,
              message: updateUserWallet.message,
            },
            "id",
            updateUserWalletData
          );

          reload();
          onClose();
          setLoading(false);
        }}
      >
        <CreateInput
          name="Amount"
          type={"number"}
          onChange={(e) => {
            setUpdateUserWallet((prev) => ({
              ...prev,
              amount: e.target.value,
            }));
          }}
          value={updateUserWallet.amount}
        />
        <FormControl>
          <FormLabel>Coin Type</FormLabel>
          <Select
            placeholder="Select type"
            onChange={(e) => {
              setUpdateUserWallet((prev) => ({
                ...prev,
                type: e.target.value,
              }));
            }}
          >
            {coins.map((coin) => (
              <option value={coin}>{coin}</option>
            ))}
          </Select>
        </FormControl>

        <CreateInput
          name="Message"
          type={"text"}
          onChange={(e) => {
            setUpdateUserWallet((prev) => ({
              ...prev,
              message: e.target.value,
            }));
          }}
          value={updateUserWallet.message}
        />
      </CreateModal>
    </>
  );
};

export default AddToWallet;
