import { Box, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { createNewMovie, getAllMovies, getSubCategories } from "../utils/api";
import Loading from "../components/common/ui/Loading";
import { useNavigator } from "../hooks/useNavigator";
import Modal from "../components/podcasts/Modal";
import Table from "../components/podcasts/Table";
import Fallback from "../components/common/ui/Fallback";

const Movies = () => {
  const toast = useToast();
  const [allMovies, setAllMovies] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getAllMoviesHandler = async () => {
    setLoading(true);
    if (user?.getMovies) {
      const [success, data] = await getAllMovies();
      if (success) {
        setAllMovies(data);
      } else {
        toast({
          title: "Error",
          description: data,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {
    if (user) {
      getAllMoviesHandler();
    } else {
      navigate("/login");
    }
  });

  const createNewMovieHandler = async (newPodcast, id) => {
    setLoading(true);
    if (user?.addMovies) {
      const [success, res] = await createNewMovie(newPodcast);
      if (success) {
        toast({
          title: "Video Created",
          description: "Video Created Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        getAllMoviesHandler();
      } else {
        toast({
          title: "Error",
          description: "Error Creating Movie",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };

  const [categories, setCategories] = React.useState([]);
  const getAllCategories = async () => {
    const [success, data] = await getSubCategories();
    if (success) {
      setCategories(data);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "2rem",
      }}
    >
      {user?.addMovies && (
        <Modal
          save={createNewMovieHandler}
          title={"Add Video"}
          categories={categories}
        />
      )}
      {user?.getMovies ? (
        <Table
          allPodcasts={allMovies}
          actions={true}
          reload={getAllMoviesHandler}
          subcategories={categories}
        />
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default Movies;
