import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { BsPlus } from "react-icons/bs";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
const Modal = ({ save, title, series, update, color }) => {
  const toast = useToast();
  const [newGenre, setNewGenre] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [genre, setGenre] = React.useState([]);
  const [newPodcast, setNewPodcast] = React.useState({});
  const podcastDataChage = (e, name) => {
    const { value } = e.target;
    setNewPodcast((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (update) {
      setNewPodcast({
        name: update.name,
        url: update?.url,
        description: update.description,
        verticalBanner: update.verticalBanner,
        horizontalBanner: update.horizontalBanner,
        duration: update.duration,
        language: update.language,
        rating: update.rating,
        ageLimit: update.ageLimit,
        type: update.type,
      });
      setGenre(update.genres);
    }
  }, []);

  const saveHandler = async () => {
    if (
      newPodcast.name &&
      newPodcast.description &&
      newPodcast.verticalBanner &&
      newPodcast.horizontalBanner &&
      newPodcast.duration &&
      newPodcast.language &&
      newPodcast.rating &&
      newPodcast.ageLimit &&
      newPodcast.type &&
      genre.length > 0
    ) {
      const newPodcastData = {
        ...newPodcast,
        genres: genre,
      };

      await save(newPodcastData, update?._id);
      onClose();
    } else {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Box
      sx={{
        gap: 3,
        width: "100%",
      }}
    >
      <Button
        onClick={onOpen}
        leftIcon={!color && <BsPlus />}
        colorScheme={color ? color : "blue"}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={saveHandler}
      >
        <CreateInput
          name="Name"
          onChange={(e) => podcastDataChage(e, "name")}
          value={newPodcast?.name ? newPodcast.name : ""}
        />
        <CreateInput
          name="Description"
          onChange={(e) => {
            podcastDataChage(e, "description");
          }}
          value={newPodcast?.description ? newPodcast.description : ""}
        />
        <Select
          placeholder="Select type"
          onChange={(e) => {
            podcastDataChage(e, "type");
          }}
          value={newPodcast?.type ? newPodcast.type : ""}
        >
          <option value="Youtube">Youtube</option>
          <option value="Vimeo">Vimeo</option>
        </Select>
        {!series && (
          <CreateInput
            name="Url"
            onChange={(e) => {
              podcastDataChage(e, "url");
            }}
            value={newPodcast?.url ? newPodcast.url : ""}
          />
        )}
        <CreateInput
          name={"Horizontal-Banner"}
          onChange={(e) => {
            podcastDataChage(e, "horizontalBanner");
          }}
          value={
            newPodcast?.horizontalBanner ? newPodcast.horizontalBanner : ""
          }
        />
        <CreateInput
          name={"Vertical-Banner"}
          onChange={(e) => {
            podcastDataChage(e, "verticalBanner");
          }}
          value={newPodcast?.verticalBanner ? newPodcast.verticalBanner : ""}
        />
        <CreateInput
          name="Duration"
          onChange={(e) => {
            podcastDataChage(e, "duration");
          }}
          value={newPodcast?.duration ? newPodcast.duration : ""}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 0rem",
            gap: ".5rem",
          }}
        >
          <FormLabel size={"sm"}>Genre</FormLabel>
          {genre?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                flexDirection: "column",
                backgroundColor: "#eee",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              {genre.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>{item}</Text>
                  <Text
                    sx={{
                      color: "red.500",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const newGenre = genre.filter((item, i) => i !== index);
                      setGenre(newGenre);
                    }}
                  >
                    Remove
                  </Text>
                </Box>
              ))}
            </Box>
          )}
          <InputGroup size="md">
            <Input
              pr="4.5rem"
              placeholder="Add"
              value={newGenre}
              onChange={(e) => {
                setNewGenre(e.target.value);
              }}
            />
            <InputRightElement width="7rem">
              <Button
                size="sm"
                onClick={() => {
                  setGenre((prev) => [...prev, newGenre]);
                  setNewGenre("");
                }}
              >
                Create Genre
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
        <CreateInput
          name="Rating"
          onChange={(e) => {
            setNewPodcast((prev) => {
              return {
                ...prev,
                rating: e.target.value,
              };
            });
          }}
          value={newPodcast?.rating ? newPodcast.rating : ""}
        />
        <CreateInput
          name="Language"
          onChange={(e) => {
            setNewPodcast((prev) => {
              return {
                ...prev,
                language: e.target.value,
              };
            });
          }}
          value={newPodcast?.language ? newPodcast.language : ""}
        />
        <CreateInput
          name="Age-Limit"
          onChange={(e) => {
            setNewPodcast((prev) => {
              return {
                ...prev,
                ageLimit: e.target.value,
              };
            });
          }}
          value={newPodcast?.ageLimit ? newPodcast.ageLimit : ""}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 0rem",
            gap: ".5rem",
          }}
        ></Box>
      </CreateModal>
    </Box>
  );
};

export default Modal;
