import React from "react";
import CreateTable from "../components/common/Table/Table";
import {
  Box,
  Td,
  useToast,
  Image,
  Select,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import {
  acceptUploadContentRequest,
  deleteUploadRequest,
  getSubCategories,
  getUploadContentRequest,
  rejectUploadContentRequest,
} from "../utils/api";
import Modal from "../components/podcasts/Modal";
import Loading from "../components/common/ui/Loading";
import DeleteModal from "../components/common/Modal/DeleteModal";
import useStore from "../utils/store";
import Fallback from "../components/common/ui/Fallback";
import CreateInput from "../components/common/TextInputs/Input";
import useActions from "../hooks/useActions";

const UploadRequest = () => {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("All");

  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  const user = useStore((state) => state.user);

  const getDataHandler = async () => {
    const [success, data] = await getUploadContentRequest();
    if (success) {
      setData(data);
      setFilteredData(data);
    }
  };

  const getAllCategories = async () => {
    const [success, data] = await getSubCategories();
    if (success) {
      setCategories(data);
    }
  };

  React.useEffect(() => {
    if (user?.uploadAccess) {
      getDataHandler();
      getAllCategories();
    }
  }, [user]);
  if (loading) return <Loading />;
  console.log(categories, data);
  return (
    <>
      {user?.uploadAccess ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Upload Request
            </h1>
            <Select
              width={"200px"}
              onChange={(e) => {
                if (e.target.value === "All") {
                  setFilteredData(data);
                } else {
                  setFilteredData(
                    data.filter((item) => item.subcategory === e.target.value)
                  );
                }
              }}
            >
              <option value="All">All</option>
              {categories?.map((item, index) => {
                return (
                  <option key={index} value={item.title}>
                    {item.title}
                  </option>
                );
              })}
            </Select>
          </div>
          <Tabs variant="enclosed">
            <TabList>
              <Tab>Pending</Tab>
              <Tab>Approved</Tab>
              <Tab>Permanent Reject</Tab>
              <Tab>Reject</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <TabsTable
                  filteredData={filteredData}
                  type="Pending"
                  setLoading={setLoading}
                  getAllCategories={getAllCategories}
                  getDataHandler={getDataHandler}
                />
              </TabPanel>{" "}
              <TabPanel>
                <TabsTable
                  filteredData={filteredData}
                  type="Approved"
                  setLoading={setLoading}
                  getAllCategories={getAllCategories}
                  getDataHandler={getDataHandler}
                />
              </TabPanel>
              <TabPanel>
                <TabsTable
                  filteredData={filteredData}
                  type="Permanent Reject"
                  setLoading={setLoading}
                  getAllCategories={getAllCategories}
                  getDataHandler={getDataHandler}
                />
              </TabPanel>
              <TabPanel>
                <TabsTable
                  filteredData={filteredData}
                  type="Reject"
                  setLoading={setLoading}
                  getAllCategories={getAllCategories}
                  getDataHandler={getDataHandler}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <Fallback />
      )}
    </>
  );
};

export default UploadRequest;

const TabsTable = ({
  filteredData,
  setLoading,
  getAllCategories,
  type,
  getDataHandler,
}) => {
  const [updateAction, deleteAction] = useActions();
  const toast = useToast();
  const [remark, setRemark] = React.useState("");
  const [open, setOpen] = React.useState(null);
  return (
    <div
      style={{
        display: "flex",

        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "20rem",
          overflow: "auto",
        }}
      >
        <CreateTable
          data={filteredData?.filter((item) => item.status === type)}
          head={[
            type !== "Approved" &&
              type !== "Permanent Reject" &&
              type !== "Reject" &&
              "Action",
            "Title",
          ]}
          rows={(row) => (
            <>
              {row?.status !== "Approved" &&
                row?.status !== "Permanent Reject" &&
                row?.status !== "Reject" && (
                  <Td>
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          flexDirection: "column",
                        }}
                      >
                        <>
                          {" "}
                          <Modal
                            buttonText={"Confirm Approve"}
                            update={{
                              url: row?.url,
                              name: row?.name,
                              urlType: row?.urlType,
                              description: row?.description,
                              verticalBanner: row?.verticalBanner,
                              horizontalBanner: row?.horizontalBanner,
                              duration: row?.duration,
                              language: row?.language,
                              rating: row?.rating,
                              ageLimit: row?.ageLimit,
                              trailer: row?.trailer,
                              trailerType: row?.trailerType,
                              extraCast: row?.extraCast,
                              genres: row?.genres,
                              cast: row?.cast,
                            }}
                            save={async (res) => {
                              setLoading(true);
                              const [success, data] =
                                await acceptUploadContentRequest({
                                  ...res,
                                  uploadedBy: row?.user?._id,
                                  requestId: row?._id,
                                });
                              if (success) {
                                toast({
                                  title: "Success",
                                  description:
                                    "Upload Request has been approved",
                                  status: "success",
                                  duration: 9000,
                                  isClosable: true,
                                });
                                getDataHandler();
                              } else {
                                toast({
                                  title: "Error",
                                  description: data?.message,
                                  status: "error",
                                  duration: 9000,
                                  isClosable: true,
                                });
                              }
                              setLoading(false);
                            }}
                            title={"Approve"}
                            color={"green"}
                          />
                          <DeleteModal
                            reject=" Reject"
                            onClick={async () => {
                              setLoading(true);
                              const [success, data] =
                                await rejectUploadContentRequest({
                                  requestId: row._id,
                                  status: "Reject",
                                  remark: remark,
                                });
                              if (success) {
                                toast({
                                  title: "Success",
                                  description:
                                    "Upload Request has been rejected",
                                  status: "success",
                                  duration: 9000,
                                  isClosable: true,
                                });
                                setRemark("");
                                getDataHandler();
                              } else {
                                toast({
                                  title: "Error",
                                  description: data?.message,
                                  status: "error",
                                  duration: 9000,
                                  isClosable: true,
                                });
                              }
                              setLoading(false);
                            }}
                          >
                            <CreateInput
                              name="Remark"
                              onChange={(e) => setRemark(e.target.value)}
                              value={remark}
                            />
                          </DeleteModal>
                          <DeleteModal
                            reject="Permanent Reject"
                            onClick={async () => {
                              setLoading(true);
                              const [success, data] =
                                await rejectUploadContentRequest({
                                  requestId: row._id,
                                  status: "Permanent Reject",
                                  remark: remark,
                                });
                              if (success) {
                                toast({
                                  title: "Success",
                                  description:
                                    "Upload Request has been rejected",
                                  status: "success",
                                  duration: 9000,
                                  isClosable: true,
                                });
                                setRemark("");
                                getDataHandler();
                              } else {
                                toast({
                                  title: "Error",
                                  description: data?.message,
                                  status: "error",
                                  duration: 9000,
                                  isClosable: true,
                                });
                              }
                              setLoading(false);
                            }}
                          >
                            <CreateInput
                              name="Remark"
                              onChange={(e) => setRemark(e.target.value)}
                              value={remark}
                            />
                          </DeleteModal>
                        </>

                        <DeleteModal
                          onClick={async () => {
                            setLoading(true);
                            const [success, data] = await deleteUploadRequest(
                              row._id
                            );
                            if (success) {
                              setLoading(false);
                              getAllCategories();
                            } else {
                              setLoading(false);
                              toast({
                                title: "Error",
                                description: data?.message,
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                              });
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Td>
                )}
              <Td>
                {/* <Sheet data={row} /> */}
                <Button
                  colorScheme="teal"
                  variant={open?._id === row?._id ? "solid" : "link"}
                  onClick={() => setOpen(row)}
                >
                  {row?.name}
                </Button>
              </Td>
            </>
          )}
        />
      </div>
      {open && (
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            width: "100%",
            flex: 1,
          }}
        >
          <Sheet data={open} />
        </div>
      )}
    </div>
  );
};

const Sheet = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <p style={{ fontWeight: "bold" }}>Description</p>
      <p>{data?.description}</p>
      <div style={{ display: "flex", gap: "10px" }}>
        <Image
          src={data?.horizontalBanner}
          alt="horizontal-banner"
          sx={{
            width: "50%",
            aspectRatio: "16/9",
            border: "1px solid #f7f7f7",
          }}
          rounded="md"
          objectFit={"contain"}
        />
        <Image
          src={data?.verticalBanner}
          alt="vertical-banner"
          sx={{
            width: "50%",
            aspectRatio: "16/9",
            border: "1px solid #f7f7f7",
          }}
          rounded="md"
          objectFit={"contain"}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Duration</p>
        <p>{data?.duration}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Age Limit</p>
        <p>{data?.ageLimit}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Rating</p>
        <p>{data?.rating}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Trailer</p>
        <a href={data?.trailer} target="_blank" rel="noreferrer">
          <Button colorScheme="teal" variant={"link"}>
            {" "}
            View
          </Button>
        </a>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Cast</p>
        <p>
          {data?.cast
            ?.map((item) => item?.name + "-" + item?.role)
            ?.join(" , ")}
        </p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Extra Cast</p>
        <p>{data?.extraCast}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Genres</p>
        <p>
          {data.genres.map((item, index) => {
            return <p key={index}>{item.name}</p>;
          })}
        </p>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Video</p>
        <a href={data?.url} target="_blank" rel="noreferrer">
          <Button colorScheme="teal" variant={"link"}>
            View
          </Button>
        </a>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Amount</p>
        <p>₹{data?.amount}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>User</p>
        <p>{data?.user?.name}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Email</p>
        <p>{data?.user?.email}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Phone Number</p>
        <p>{data?.user?.phoneNumber}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Remark</p>
        <p>{data?.remark}</p>
      </Box>
    </>
  );
};
