import {
  Box,
  Button,
  Link,
  Select,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DeleteModal from "../components/common/Modal/DeleteModal";
import CreateModal from "../components/common/Modal/Modal";
import CreateTable from "../components/common/Table/Table";
import CreateInput from "../components/common/TextInputs/Input";
import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import useActions from "../hooks/useActions";
import { useNavigator } from "../hooks/useNavigator";
import {
  createNewBanner,
  deleteBanner,
  getAllBanners,
  getAllMovies,
  getAllSeries,
  updateBanner,
} from "../utils/api";

const Banner = () => {
  const [banner, setBanner] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [newBanner, setNewBanner] = React.useState({});
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = React.useState(true);
  const [updateAction, deleteAction] = useActions();
  const [itemData, setItemData] = React.useState([]);
  const [itemType, setItemType] = React.useState("");

  const bannersHandler = async () => {
    if (user?.getBanner) {
      const [success, data] = await getAllBanners();
      if (success) {
        setBanner(data);
      }
    }
    setLoading(false);
  };
  const getAllMovieHanler = async () => {
    setLoading(true);
    const [success, data] = await getAllMovies();
    if (success) {
      setItemData(data);
    }
    setLoading(false);
  };

  const getAllSeriesHandler = async () => {
    setLoading(true);
    const [success, data] = await getAllSeries();
    if (success) {
      setItemData(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (itemType === "movie") {
      getAllMovieHanler();
    } else if (itemType === "series") {
      getAllSeriesHandler();
    }
  }, [itemType]);

  const [navigate, user] = useNavigator(bannersHandler);
  const createBanner = async () => {
    if (newBanner.position && newBanner.url && newBanner.origin) {
      setLoading(true);
      if (!update && user?.addBanner) {
        const [success, data] = await createNewBanner(newBanner);
        if (success) {
          toast({
            title: "Banner Created",
            description: "Banner Created Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          bannersHandler();
          onClose();
        } else {
          toast({
            title: "Banner Not Created",
            description: "Banner Not Created Successfully",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        await updateAction(
          {
            ...newBanner,
          },
          newBanner._id,
          updateBanner
        );
        setUpdate(false);
        onClose();
        bannersHandler();
      }
    } else {
      toast({
        title: "fill all fields",
        description: "Banner Not Created Successfully",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      {user?.addBanner && (
        <Button
          colorScheme="blue"
          variant="solid"
          onClick={() => {
            onOpen();
            setItemType("");
            setItemData({});
            setUpdate(false);
            setNewBanner({});
          }}
        >
          Create Banner
        </Button>
      )}
      {user?.getBanner ? (
        <CreateTable
          data={banner}
          head={["Image", "Position", "Origin", "Links"]}
          rows={(row) => {
            return (
              <>
                <Td>
                  <Link
                    href={row.url}
                    isExternal
                    sx={{
                      color: "blue.500",
                      textDecoration: "underline",
                    }}
                  >
                    Link
                  </Link>
                </Td>
                <Td>{row.position}</Td>
                <Td>{row.origin}</Td>
                <Td>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                    }}
                  >
                    {user?.updateBanner && (
                      <Button
                        colorScheme={row.isEnable ? "red" : "green"}
                        variant="solid"
                        onClick={async () => {
                          setLoading(true);
                          await updateAction(
                            {
                              isEnable: !row.isEnable,
                            },
                            row._id,
                            updateBanner
                          );
                          setLoading(false);
                          bannersHandler();
                        }}
                        sx={{
                          width: "100%",
                        }}
                      >
                        {row.isEnable ? "Disable" : "Enable"}
                      </Button>
                    )}

                    {user?.updateBanner && (
                      <Button
                        colorScheme="blue"
                        variant="solid"
                        onClick={() => {
                          setNewBanner(row);
                          setUpdate(true);
                          onOpen();
                        }}
                        sx={{
                          width: "100%",
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {user?.deleteBanner && (
                      <DeleteModal
                        onClick={async () => {
                          setLoading(true);
                          await deleteAction(row._id, deleteBanner);
                          setLoading(false);
                          bannersHandler();
                        }}
                      />
                    )}
                  </Box>
                </Td>
              </>
            );
          }}
        />
      ) : (
        <Fallback />
      )}
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title="Create Banner"
        create={createBanner}
      >
        <CreateInput
          name={"Url"}
          onChange={(e) => {
            setNewBanner((prev) => ({
              ...prev,
              url: e.target.value,
            }));
          }}
          value={newBanner?.url ? newBanner.url : ""}
        />
        <Select
          value={newBanner?.origin ? newBanner.origin : ""}
          placeholder="Select origin"
          onChange={(e) => {
            setNewBanner((prev) => ({
              ...prev,
              origin: e.target.value,
            }));
          }}
        >
          <option value="Youtube">Youtube</option>
          <option value="Video">Video</option>
          <option value="Image">Image</option>
        </Select>
        <CreateInput
          name={"Position"}
          type="number"
          onChange={(e) => {
            setNewBanner((prev) => ({
              ...prev,
              position: e.target.value,
            }));
          }}
          value={newBanner?.position ? newBanner.position : ""}
        />
      </CreateModal>
    </Box>
  );
};

export default Banner;
