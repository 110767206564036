import React from "react";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
const Player = () => {
  const { state } = useLocation();
  console.log(state);
  return (
    <div
      style={{
        aspectRatio: "16/9",
        width: "100%",
      }}
    >
      <ReactPlayer
        url={state.url}
        controls={true}
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
};

export default Player;
