import {
  Card,
  CardBody,
  Image,
  Stack,
  Divider,
  ButtonGroup,
  Button,
  Heading,
  CardFooter,
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { RiPassportLine, RiUserLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";
import Logo from "../assets/logo.png";
import { loginEmail } from "../utils/api";
const Login = () => {
  const [data, setData] = React.useState({});
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const login = useStore((state) => state.login);

  const loginHandler = async () => {
    if (data.email && data.password) {
      setLoading(true);
      const [status, res] = await loginEmail(data.email, data.password);
      if (status) {
        login(res);

        navigate("/");
        toast({
          title: "Login Success",
          description: "You have successfully logged in",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Login Failed",

          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Login Failed",
        description: "Please enter your credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      bg="gray.50"
    >
      <Card maxW="lg" bg="white">
        <CardBody>
          <Image
            src={Logo}
            alt="Green double couch with wooden legs"
            h="100px"
            bg={"white"}
          />
          <Stack mt="6" spacing="3">
            <Heading size="lg">Login</Heading>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<RiUserLine />}
              />
              <Input
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setData({ ...data, email: e.target.value });
                }}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<RiPassportLine />}
              />
              <Input
                type="Password"
                placeholder="Password"
                width={300}
                onChange={(e) => {
                  setData((prev) => ({ ...prev, password: e.target.value }));
                }}
              />
            </InputGroup>
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter>
          <ButtonGroup spacing="2">
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={loginHandler}
              isLoading={loading}
            >
              Login
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default Login;
