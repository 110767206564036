import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Tag,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { FaInfo } from "react-icons/fa";

const CreateInput = ({ name, onChange, value, type, step, tooltip }) => {
  return (
    <FormControl>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 51,
        }}
      >
        <FormLabel>{name}</FormLabel>{" "}
        {tooltip && (
          <Tooltip hasArrow label={tooltip}>
            <CustomCard />
          </Tooltip>
        )}{" "}
      </div>
      <Input
        required
        placeholder={name}
        onChange={onChange}
        value={value}
        type={type ? type : "text"}
        step={step}
      />
    </FormControl>
  );
};

export default CreateInput;
const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Box p="1">
    <Tag ref={ref} {...rest}>
      <FaInfo />
    </Tag>
  </Box>
));
