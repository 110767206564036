import {
  Box,
  Button,
  Checkbox,
  Image,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import File from "../common/TextInputs/File";
import { addUser, updateUser } from "../../utils/api";
import Loading from "../common/ui/Loading";
const Modal = ({ title, reload, update, updateData }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    image: "",
    aadharNumber: "",
    ifsc: "",
    accountNumber: "",
    bankName: "",
    upi: "",
    branchName: "",
    panNumber: "",
  });
  const createUserHandler = async () => {
    const formData = new FormData();
    formData.append("image", user.image ? user.image : "");
    formData.append("email", user.email);
    formData.append("phoneNumber", user.phoneNumber);
    formData.append("name", user.name);
    formData.append("dob", user.dob);
    setIsLoading(true);
    if (!update) {
      const [success, res] = await addUser(formData);
      if (success) {
        toast({
          title: "User Created",
          description: "User has been created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reload();
      } else {
        toast({
          title: "Error",
          description: "Error creating user",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      const data = {
        email: user.email,
        phoneNumber: user.phoneNumber,
        name: user.name,
        aadharNumber: user.aadharNumber,
        ifsc: user.ifsc,
        accountNumber: user.accountNumber,
        bankName: user.bankName,
        upi: user.upi,
        branchName: user.branchName,
        panNumber: user.panNumber,
        isBankDetailAdded: user.isBankDetailAdded,
      };
      const [success] = await updateUser(data, updateData._id);
      if (success) {
        toast({
          title: "User Updated",
          description: "User has been updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reload();
      } else {
        toast({
          title: "Error",
          description: "Error updating user",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (update) {
      setUser(update);
    }
  }, [update]);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Button
        colorScheme="blue"
        variant="solid"
        onClick={onOpen}
        sx={{
          width: "100%",
        }}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={createUserHandler}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <CreateInput
            name="Name"
            onChange={(e) => {
              setUser({ ...user, name: e.target.value });
            }}
            value={user?.name}
          />
          <CreateInput
            name="Email"
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            type="email"
            value={user.email}
          />
          <CreateInput
            name="Phone Number"
            onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
            type="text"
            value={user.phoneNumber}
          />
          <File
            name={"Profile Image"}
            onChange={(e) => setUser({ ...user, image: e.target.files[0] })}
          />
          {update && (
            <Image
              src={user?.image}
              sx={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
            />
          )}
          {update && (
            <>
              <CreateInput
                name="Aadhar Number"
                onChange={(e) =>
                  setUser({ ...user, aadharNumber: e.target.value })
                }
                type="text"
                value={user.aadharNumber}
              />
              <CreateInput
                name="IFSC"
                onChange={(e) => setUser({ ...user, ifsc: e.target.value })}
                type="text"
                value={user.ifsc}
              />
              <CreateInput
                name="Account Number"
                onChange={(e) =>
                  setUser({ ...user, accountNumber: e.target.value })
                }
                type="text"
                value={user.accountNumber}
              />
              <CreateInput
                name="Bank Name"
                onChange={(e) => setUser({ ...user, bankName: e.target.value })}
                type="text"
                value={user.bankName}
              />
              <CreateInput
                name="UPI"
                onChange={(e) => setUser({ ...user, upi: e.target.value })}
                type="text"
                value={user.upi}
              />
              <CreateInput
                name="Branch Name"
                onChange={(e) =>
                  setUser({ ...user, branchName: e.target.value })
                }
                type="text"
                value={user.branchName}
              />
              <CreateInput
                name="Pan Number"
                onChange={(e) =>
                  setUser({ ...user, panNumber: e.target.value })
                }
                type="text"
                value={user.panNumber}
              />
              <Checkbox
                onChange={(e) =>
                  setUser({ ...user, isBankDetailAdded: e.target.checked })
                }
                isChecked={user.isBankDetailAdded}
              >
                Bank Detail Added
              </Checkbox>
            </>
          )}
        </Box>
      </CreateModal>
    </Box>
  );
};

export default Modal;
