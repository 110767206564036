import { Box, Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import { BiPodcast } from "react-icons/bi";
import {
  FcBusinessman,
  FcCamcorderPro,
  FcFilingCabinet,
  FcParallelTasks,
  FcStackOfPhotos,
} from "react-icons/fc";
import { GrMultiple } from "react-icons/gr";
import CreateModal from "../components/common/Modal/Modal";
import CheckboxInput from "../components/common/TextInputs/Checkbox";
import CreateInput from "../components/common/TextInputs/Input";
import Loading from "../components/common/ui/Loading";
import Card from "../components/Dashboard/Card";
import { useNavigator } from "../hooks/useNavigator";
import { getDashboardData, systemValueUpdate } from "../utils/api";
const Home = () => {
  const [systemValues, setSystemValues] = React.useState({});
  const [dashboardData, setDashboardData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [type, setType] = React.useState("system");

  const toast = useToast();
  const getDashboardDataHandler = async () => {
    setLoading(true);
    const [success, data] = await getDashboardData();
    if (success) {
      setDashboardData(data);
      setSystemValues(data.systemValues);
      console.log(data?.systemValues?.adminCharges);
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {
    if (user) {
      getDashboardDataHandler();
    } else {
      navigate("/login");
    }
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const changeHandler = (e, name) => {
    setSystemValues((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Box sx={{ p: "10" }}>
      {user?.addAdmin ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
          <Button
            onClick={() => {
              setType("system");
              onOpen();
            }}
            colorScheme="blue"
          >
            System Value
          </Button>
          <Button
            onClick={() => {
              setType("payment");
              onOpen1();
            }}
            colorScheme="blue"
          >
            Payment Gateway
          </Button>
        </Box>
      ) : (
        <div></div>
      )}
      <Text
        fontSize="4xl"
        fontWeight="bold"
        mb="5"
        sx={{
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderBottom: "1px solid #eee",
        }}
      >
        Dashboard <FcFilingCabinet />
      </Text>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <Card
          icon={<BiPodcast size={24} />}
          color={"purple"}
          name="Videos"
          number={dashboardData?.movies}
        />
        <Card
          icon={<GrMultiple size={24} />}
          color={"pink"}
          name="Series"
          number={dashboardData?.series}
        />
        <Card
          icon={<FcBusinessman size={24} />}
          color="blue"
          name="Users"
          number={dashboardData?.users}
        />
        <Card
          icon={<FcStackOfPhotos size={24} />}
          color={"yellow"}
          name="Banners"
          number={dashboardData?.banners}
        />
        <Card
          icon={<FcParallelTasks size={24} />}
          color={"green"}
          name="Categories"
          number={dashboardData?.categories}
        />
        <Card
          icon={<FcCamcorderPro size={24} />}
          color={"red"}
          name="Subscription"
          number={dashboardData?.subscription}
        />
      </Box>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title="System Settings (in ₹)"
        create={async () => {
          setLoading(true);
          const [success, data] = await systemValueUpdate(systemValues);
          if (success) {
            toast({
              title: "System Values Updated",
              description: "System Values Updated Successfully",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            getDashboardDataHandler();
          }
          onClose();
          setLoading(false);
        }}
      >
        <CreateInput
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
          name={"Redeem-Amount"}
          value={systemValues?.redeemAmount}
          onChange={(e) => changeHandler(e, "redeemAmount")}
        />
        <CreateInput
          name={"Referral-First"}
          value={systemValues?.referralFirst}
          onChange={(e) => changeHandler(e, "referralFirst")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Referral-Second"}
          value={systemValues?.referralSecond}
          onChange={(e) => changeHandler(e, "referralSecond")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Referral-Third"}
          value={systemValues?.referralThird}
          onChange={(e) => changeHandler(e, "referralThird")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Referral-Four"}
          value={systemValues?.referralFour}
          onChange={(e) => changeHandler(e, "referralFour")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Referral-Five"}
          value={systemValues?.referralFive}
          onChange={(e) => changeHandler(e, "referralFive")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Referral-Fixed-Price"}
          value={systemValues?.referralFixedPrice}
          onChange={(e) => changeHandler(e, "referralFixedPrice")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Referral-Coin"}
          value={systemValues?.referralCoin}
          onChange={(e) => changeHandler(e, "referralCoin")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Promotional-Coin"}
          value={systemValues?.promotionalCoin}
          onChange={(e) => changeHandler(e, "promotionalCoin")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"App-Sharing-Coin"}
          value={systemValues?.appSharingCoin}
          onChange={(e) => changeHandler(e, "appSharingCoin")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Content-Sharing-Coin"}
          value={systemValues?.contentSharingCoin}
          onChange={(e) => changeHandler(e, "contentSharingCoin")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Reward-Video-Coin"}
          value={systemValues?.rewardVideoCoin}
          onChange={(e) => changeHandler(e, "rewardVideoCoin")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Admin Charges"}
          value={systemValues?.adminCharges}
          onChange={(e) => changeHandler(e, "adminCharges")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Upi Charges"}
          value={systemValues?.upiCharges}
          onChange={(e) => changeHandler(e, "upiCharges")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Upload Charges"}
          value={systemValues?.uploadCharge}
          onChange={(e) => changeHandler(e, "uploadCharge")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"View Amount"}
          value={systemValues?.viewAmount}
          onChange={(e) => changeHandler(e, "viewAmount")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Indian Icon"}
          value={systemValues?.contestCharge}
          onChange={(e) => changeHandler(e, "contestCharge")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
        <CreateInput
          name={"Login Text"}
          value={systemValues?.loginText}
          onChange={(e) => changeHandler(e, "loginText")}
          tooltip={
            "This is the amount that you will get if you redeem your referral code. eg. 1000"
          }
        />
      </CreateModal>
      <CreateModal
        isOpen={isOpen1}
        onClose={onClose1}
        title="Payment Gateway"
        create={async () => {
          setLoading(true);
          const [success, data] = await systemValueUpdate(systemValues);
          if (success) {
            toast({
              title: "Payment Details Updated",
              description: "Payment Details Updated Successfully",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            getDashboardDataHandler();
          }
          onClose1();
          setLoading(false);
        }}
      >
        <CheckboxInput
          isChecked={systemValues?.isPhonePeEnabled}
          onChange={(e) =>
            changeHandler(
              {
                target: {
                  name: "isPhonePeEnabled",
                  value: e.target.checked,
                },
              },
              "isPhonePeEnabled"
            )
          }
          name="PhonePe"
        />
        <CheckboxInput
          isChecked={systemValues?.isRazorpayEnabled}
          onChange={(e) =>
            changeHandler(
              {
                target: {
                  name: "isRazorpayEnabled",
                  value: e.target.checked,
                },
              },
              "isRazorpayEnabled"
            )
          }
          name="Razorpay"
        />
        <CheckboxInput
          isChecked={systemValues?.isPaytmEnabled}
          onChange={(e) =>
            changeHandler(
              {
                target: {
                  name: "isPaytmEnabled",
                  value: e.target.checked,
                },
              },
              "isPaytmEnabled"
            )
          }
          name="PayTM"
        />
        <CreateInput
          name="PhonePe Merchant Key"
          value={systemValues?.phonePeMerchantKey}
          onChange={(e) => changeHandler(e, "phonePeMerchantKey")}
        />
        <CreateInput
          name="PhonePe Salt"
          value={systemValues?.phonePeSalt}
          onChange={(e) => changeHandler(e, "phonePeSalt")}
        />
        <CreateInput
          name="Razorpay Key"
          value={systemValues?.razorpayKey}
          onChange={(e) => changeHandler(e, "razorpayKey")}
        />
        <CreateInput
          name="Razorpay Secret"
          value={systemValues?.razorpaySecret}
          onChange={(e) => changeHandler(e, "razorpaySecret")}
        />
        <CreateInput
          name="Paytm Merchant Id"
          value={systemValues?.paytmMerchantId}
          onChange={(e) => changeHandler(e, "paytmMerchantId")}
        />
      </CreateModal>
    </Box>
  );
};

export default Home;
