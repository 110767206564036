import { Box, Button, Select, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  addDataToSubcription,
  getAllSubscribtions,
  removeFromSubscription,
} from "../../utils/api";
import CreateModal from "../common/Modal/Modal";
import Loading from "../common/ui/Loading";

const ModalSub = ({ item, reload, series, episeries }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [newData, setNewData] = React.useState({});
  const [subData, setSubData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [subsChange, setSubsChange] = React.useState("");
  const [subs, setSubs] = React.useState([]);

  const getAllSubscribtionsHandler = async () => {
    const [success, data] = await getAllSubscribtions();
    if (success) {
      setSubs(data);
    } else {
      toast({
        title: "Error",
        description: data,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    getAllSubscribtionsHandler();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        {/* <Button
          colorScheme="blue"
          onClick={() => {
            onOpen();
            setSubsChange("add");
            setNewData((prev) => {
              return {
                ...prev,
                [series ? "seriesId" : "movieId"]: item?._id,
                type: series ? "Series" : "Movie",
              };
            });
          }}
        >
          Add to Subcription
        </Button> */}

        <Button
          colorScheme="blue"
          onClick={() => {
            onOpen();
            setSubsChange("remove");
            setSubData(item?.subscriptions);
            setNewData((prev) => {
              return {
                ...prev,
                [series ? "seriesId" : "movieId"]: item?._id,
                type: series ? "Series" : "Movie",
              };
            });
          }}
        >
          Remove from Subcription
        </Button>
      </Box>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={
          subsChange === "add"
            ? "Add to Subcription"
            : "Remove from Subcription"
        }
        create={async () => {
          setLoading(true);
          if (subsChange === "add") {
            const [success, data] = await addDataToSubcription(newData);
            if (success) {
              toast({
                title: "Success",
                description: data.message,
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              onClose();
              reload();
            } else {
              toast({
                title: "Error",
                description: data.message,
                status: "error",

                duration: 9000,
                isClosable: true,
              });
            }
          } else {
            const [success, data] = await removeFromSubscription(newData);
            if (success) {
              toast({
                title: "Success",
                description: data.message,
                status: "success",
                duration: 9000,
                isClosable: true,
              });
              onClose();
              reload();
            } else {
              toast({
                title: "Error",
                description: data.message,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
          }
          setLoading(false);
        }}
      >
        <Select
          placeholder="Select option"
          onChange={(e) => {
            setNewData((prev) => {
              return {
                ...prev,
                subscriptionId: e.target.value,
              };
            });
          }}
        >
          {subsChange === "add"
            ? subs.map((item, index) => {
                return (
                  <option key={index} value={item._id}>
                    {item.title}
                  </option>
                );
              })
            : subData?.map((item, index) => {
                return (
                  <option key={index} value={item._id}>
                    {item.title}
                  </option>
                );
              })}
        </Select>
      </CreateModal>
    </>
  );
};

export default ModalSub;
