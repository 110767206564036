import { Box, Button, Td, Text } from "@chakra-ui/react";
import React from "react";
import CreateTable from "../components/common/Table/Table";
import Loading from "../components/common/ui/Loading";
import { getPurchaseRecords } from "../utils/api";
import useStore from "../utils/store";

const PurchaseRecords = () => {
  const [data, setData] = React.useState({});
  const limit = 50;
  const total = data?.metadata?.totalData;
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const getAllData = async () => {
    setLoading(true);
    const [success, data] = await getPurchaseRecords(page);
    if (success) {
      window.scrollTo(0, 0);

      setData(data);
    }
    setLoading(false);
  };
  console.log(data);
  const user = useStore((state) => state.user);
  React.useEffect(() => {
    getAllData();
  }, [page]);

  return user?.getPurchaseRecords ? (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                marginBlock: "20px",
              }}
            >
              Purchase Records
            </h1>
          </div>
          <CreateTable
            data={
              data
                ? data?.data?.map((item) => {
                    return {
                      name: item?.user?.name,
                      subscription: item?.subscription?.title,
                      price: item?.subscription?.price,
                      date: item?.purchasedDate,
                    };
                  })
                : []
            }
            head={["Name", "Subscription", "Price", "Date"]}
            rows={(row) => {
              return (
                <>
                  <Td>{row?.name}</Td>
                  <Td>{row?.subscription}</Td>
                  <Td>{row?.price}</Td>
                  <Td>{new Date(row?.date ?? "").toLocaleDateString()}</Td>
                </>
              );
            }}
          />
          <Box
            sx={{
              padding: "20px",
            }}
          >
            <Text
              fontSize="sm"
              color="gray.500"
              sx={{
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {`Showing ${page * limit - limit + 1} to ${
                page * limit > total ? total : page * limit
              } of ${total} entries`}
            </Text>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,

                paddingBottom: 5,
                overflow: "auto",
              }}
            >
              {new Array(Math?.ceil(total / limit))
                ?.fill(0)
                ?.map((item, index) => (
                  <Button
                    key={index}
                    onClick={() => setPage(index + 1)}
                    colorScheme={page === index + 1 ? "blue" : "gray"}
                    variant="solid"
                  >
                    {index + 1}
                  </Button>
                ))}
            </Box>
          </Box>
        </>
      )}
    </>
  ) : (
    <p style={{ textAlign: "center", color: "red", padding: "20px" }}>
      You don't have permission to access this page
    </p>
  );
};

export default PurchaseRecords;
