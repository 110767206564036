import React from "react";
import CreateTable from "../components/common/Table/Table";
import { Box, Td, useToast, Image, Select, Button } from "@chakra-ui/react";
import {
  acceptUploadContentRequest,
  addSubCategory,
  addTangentCareerCategory,
  deleteSubCategory,
  deleteTangentCareerCategory,
  getSubCategories,
  getTangentCareerCategories,
  getUploadContentRequest,
  rejectUploadContentRequest,
  updateCareerCategory,
  updateSubCategory,
} from "../utils/api";
import Modal from "../components/podcasts/Modal";
import Loading from "../components/common/ui/Loading";
import DeleteModal from "../components/common/Modal/DeleteModal";
import useStore from "../utils/store";
import Fallback from "../components/common/ui/Fallback";
import CreateModal from "../components/common/Modal/Modal";
import CreateInput from "../components/common/TextInputs/Input";
import useActions from "../hooks/useActions";

const TangentCategory = () => {
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("All");
  const [newCategory, setNewCategory] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const toast = useToast();
  const user = useStore((state) => state.user);
  const [update, setUpdate] = React.useState(false);
  const [updateAction, deleteAction] = useActions();
  const getAllCategories = async () => {
    const [success, data] = await getTangentCareerCategories();
    if (success) {
      setCategories(data);
    }
  };

  const addNewCategory = async () => {
    if (newCategory) {
      setLoading(true);
      const [success, data] = update
        ? await updateCareerCategory(
            {
              title: newCategory,
            },
            update?._id
          )
        : await addTangentCareerCategory({
            title: newCategory,
          });
      if (success) {
        toast({
          title: "Success",
          description: "New Category has been added",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setNewCategory(null);
        setUpdate(false);
        setOpen(false);
        getAllCategories();
      } else {
        toast({
          title: "Error",
          description: data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (user?.uploadAccess) {
      getAllCategories();
    }
  }, [user]);
  if (loading) return <Loading />;
  console.log(categories, data);
  return (
    <>
      {user?.uploadAccess ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Tangent Career Category
            </h1>
            {/* <Select
              width={"200px"}
              onChange={(e) => {
                if (e.target.value === "All") {
                  setFilteredData(data);
                } else {
                  setFilteredData(
                    data.filter((item) => item.subcategory === e.target.value)
                  );
                }
              }}
            >
              <option value="All">All</option>
              <option value="Movie">Movie</option>
              <option value="Short Movie">Short Movie</option>
              <option value="Songs">Songs</option>
            </Select> */}
            <Button onClick={() => setOpen(true)} colorScheme="blue">
              Add New Category
            </Button>
            <CreateModal
              create={addNewCategory}
              isOpen={open}
              onClose={() => {
                setOpen(false);
                setUpdate(false);
                setNewCategory(null);
              }}
              title={update ? "Update Category" : "Create   Category"}
            >
              <CreateInput
                name="Title"
                onChange={(e) => setNewCategory(e.target.value)}
                value={newCategory}
              />
            </CreateModal>
          </div>

          <CreateTable
            data={categories}
            head={["Title", "Actions"]}
            rows={(row) => (
              <>
                <Td>{row?.title}</Td>
                <Td>
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                    }}
                  >
                    <Button
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        setUpdate(row);
                        setNewCategory(row.title);
                        setOpen(true);
                      }}
                      sx={{
                        width: "100%",
                      }}
                    >
                      Edit
                    </Button>

                    <DeleteModal
                      onClick={async () => {
                        setLoading(true);
                        await deleteAction(
                          row._id,
                          deleteTangentCareerCategory
                        );
                        setLoading(false);
                        getAllCategories();
                      }}
                    />
                  </Box>
                </Td>
              </>
            )}
          />
        </>
      ) : (
        <Fallback />
      )}
    </>
  );
};

export default TangentCategory;
