import React, { useEffect } from "react";
import useActions from "../../hooks/useActions";
import { updateUserWalletData } from "../../utils/api";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import Loading from "../common/ui/Loading";

const UpdateWallet = ({ data, isOpenModal5, onCloseModal5, reload }) => {
  const [updateUserWallet, setUpdateUserWallet] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [updateAction, deleteAction] = useActions();
  useEffect(() => {
    setUpdateUserWallet(data);
  }, [data]);
  if (loading) {
    return <Loading />;
  }

  return (
    <CreateModal
      title={"Edit User Wallet"}
      isOpen={isOpenModal5}
      onClose={onCloseModal5}
      create={async () => {
        setLoading(true);
        await updateAction(updateUserWallet, "id", updateUserWalletData);

        reload();
        onCloseModal5();
        setLoading(false);
      }}
    >
      <CreateInput
        name="Message"
        type={"text"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            message: e.target.value,
          }));
        }}
      />

      <CreateInput
        name="Total Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            totalCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.totalCoin}
      />
      <CreateInput
        name="Referral Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            referralCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.referralCoin}
      />
      <CreateInput
        name="Promotional Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            promotionalCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.promotionalCoin}
      />
      <CreateInput
        name="App Sharing Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            appSharingCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.appSharingCoin}
      />
      <CreateInput
        name="Content Sharing Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            contentSharingCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.contentSharingCoin}
      />

      <CreateInput
        name="Reward Video Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            rewardVideoCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.rewardVideoCoin}
      />
      <CreateInput
        name="Withdrawable Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            freeCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.freeCoin}
      />
      <CreateInput
        name="Locked Coin"
        type={"number"}
        onChange={(e) => {
          setUpdateUserWallet((prev) => ({
            ...prev,
            lockedCoin: e.target.value,
          }));
        }}
        value={updateUserWallet.lockedCoin}
      />
    </CreateModal>
  );
};

export default UpdateWallet;
