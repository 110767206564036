import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import CreateModal from "../common/Modal/Modal";

const BankDetailsModal = ({ open, onClose, BankDetails }) => {
  return (
    <CreateModal isOpen={open} onClose={onClose} title={"Bank Details"} table>
      <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
        <Text sx={{ fontWeight: "700" }} size="lg">
          Bank Name : {BankDetails?.bankName}
        </Text>
        <Text sx={{ fontWeight: "700" }} size="lg">
          Branch Name : {BankDetails?.branchName}
        </Text>
        <Text sx={{ fontWeight: "700" }} size="lg">
          Account Number : {BankDetails?.accountNumber}
        </Text>
        <Text sx={{ fontWeight: "700" }} size="lg">
          Account Holder Name : {BankDetails?.name}
        </Text>
        <Text sx={{ fontWeight: "700" }} size="lg">
          upi : {BankDetails?.upi}
        </Text>
        <Text sx={{ fontWeight: "700" }} size="lg">
          IFSC Code : {BankDetails?.ifsc}
        </Text>

        <Text sx={{ fontWeight: "700" }} size="lg">
          Aadhar Number : {BankDetails?.aadharNumber}
        </Text>
        <Text sx={{ fontWeight: "700" }} size="lg">
          Pan Card Number : {BankDetails?.panNumber}
        </Text>
        <Text sx={{ fontWeight: "700" }} size="lg">
          Pan Card :
        </Text>
        <Image src={BankDetails?.pancard} boxSize="100px" />
      </Box>
    </CreateModal>
  );
};

export default BankDetailsModal;
