import {
  Alert,
  AlertIcon,
  Box,
  Button,
  IconButton,
  Image,
  Link,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { RiShutDownLine, RiMenu2Line } from "react-icons/ri";
import useStore from "../../../utils/store";
import Logo from "../../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import CreateModal from "../Modal/Modal";
import CreateInput from "../TextInputs/Input";
import { CheckPassword, updatePassword } from "../../../utils/api";
import { SiGoogleanalytics } from "react-icons/si";
const Navbar = ({ onOpen }) => {
  const navigate = useNavigate();
  const logout = useStore((state) => state.logout);
  const user = useStore((state) => state.user);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [valid, setValid] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const toast = useToast();
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [isloading, setLoading] = React.useState(false);
  const logoutFun = () => {
    logout();
    navigate("/login", { replace: true });
  };

  const checkPassword = async () => {
    setLoading(true);
    if (!user || oldPassword === "" || !isLoggedIn) {
      toast({
        title: "Error",
        description: "Fill all the fields",
        status: "error",
        duration: 9000,
        isClosable: true,
      });

      setValid(false);
      setLoading(false);
      return;
    }
    const newData = {
      email: user.email,
      password: oldPassword,
    };
    const [success, data] = await CheckPassword(newData);
    if (success && data?.isValid) {
      setValid(true);
      toast({
        title: "Success",
        description: "Password is correct",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      setValid(false);
      toast({
        title: "Error",
        description: "Password is incorrect",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const changePassword = async () => {
    setLoading(true);
    if (newPassword !== confirmPassword || newPassword === "" || !valid) {
      toast({
        title: "Error",
        description: "New password and confirm password don't match",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const newData = {
      email: user.email,
      password: newPassword,
    };
    const [success, data] = await updatePassword(newData);
    if (success) {
      toast({
        title: "Success",
        description: data.message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      logoutFun();
    } else {
      toast({
        title: data.message,
        description: "Something went wrong try again",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setValid(false);
    setConfirmPassword("");
    setNewPassword("");
    setOldPassword("");
    setEmail("");
    onCloseModal();
    setLoading(false);
  };

  return (
    <Box
      bg="white"
      w="100%"
      h="100%"
      borderBottom={{
        base: "none",
        md: "1px solid #eee",
      }}
      p={5}
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 6,
        }}
      >
        <Box sx={{ display: { base: "block", md: "none" } }}>
          <RiMenu2Line size={35} onClick={onOpen} />
        </Box>
        <Image src={Logo} alt="logo" w="50px" borderRadius={"md"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 6,
        }}
      >
        {user?.addAdmin && (
          <Link
            href="https://console.firebase.google.com/project/tangent-web-bd07c/analytics/app/android:com.tangent.web/overview"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              colorScheme="blue"
              leftIcon={<SiGoogleanalytics size={20} color={"white"} />}
            >
              Analytics
            </Button>
          </Link>
        )}
        <Button colorScheme="blue" onClick={onOpenModal}>
          Change Password
        </Button>
        <IconButton
          icon={<RiShutDownLine />}
          onClick={logoutFun}
          colorScheme="red"
        />
      </Box>
      <CreateModal
        isOpen={isOpenModal}
        onClose={onCloseModal}
        title={"Change Password"}
        button1={valid ? "Change Password" : "Check Password"}
        create={valid ? changePassword : checkPassword}
      >
        {isloading ? (
          <Box w="100%" h="100%" display="flex" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : !valid ? (
          <>
            <CreateInput
              name={"Old Password"}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </>
        ) : (
          <>
            <CreateInput
              name={"New Password"}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <CreateInput
              name={"Confirm Password"}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {newPassword === confirmPassword && newPassword !== "" && (
              <Alert status="success">
                <AlertIcon />
                Matched
              </Alert>
            )}
          </>
        )}
      </CreateModal>
    </Box>
  );
};

export default Navbar;
