import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import Loading from "../components/common/ui/Loading";
import { getChainRecords } from "../utils/api";
import useStore from "../utils/store";

const Chain = () => {
  const [data, setData] = React.useState({});
  const limit = 50;
  const total = data?.totalPages * limit;
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const getAllData = async () => {
    setLoading(true);
    const [success, data] = await getChainRecords(page);
    if (success) {
      window.scrollTo(0, 0);

      setData(data);
    }
    setLoading(false);
  };
  console.log(data);
  const user = useStore((state) => state.user);
  React.useEffect(() => {
    getAllData();
  }, [page]);

  return user?.getPurchaseRecords ? (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                marginBlock: "20px",
              }}
            >
              Referral Chain
            </h1>
          </div>
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              gap: "40px",
              padding: "20px",
            }}
          >
            {data?.referrals?.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",
                    paddingLeft: "20px",
                    gap: "10px",
                    borderLeft: "1px solid black",
                  }}
                  key={item?._id}
                >
                  {item?.list?.map((item) => {
                    return <div key={item?._id}>- {item?.user?.name}</div>;
                  })}
                </div>
              );
            })}
          </div>
          <Box
            sx={{
              padding: "20px",
            }}
          >
            <Text
              fontSize="sm"
              color="gray.500"
              sx={{
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {`Showing ${page * limit - limit + 1} to ${
                page * limit > total ? total : page * limit
              } of ${total} entries`}
            </Text>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,

                paddingBottom: 5,
                overflow: "auto",
              }}
            >
              {new Array(Math?.ceil(total / limit))
                ?.fill(0)
                ?.map((item, index) => (
                  <Button
                    key={index}
                    onClick={() => setPage(index + 1)}
                    colorScheme={page === index + 1 ? "blue" : "gray"}
                    variant="solid"
                  >
                    {index + 1}
                  </Button>
                ))}
            </Box>
          </Box>
        </>
      )}
    </>
  ) : (
    <p style={{ textAlign: "center", color: "red", padding: "20px" }}>
      You don't have permission to access this page
    </p>
  );
};

export default Chain;
