import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/common/ui/Layout";
import AddSubcription from "./pages/AddSubcription";
import Admin from "./pages/Admins";
import Banner from "./pages/Banner";
import Categories from "./pages/Categories";
import Chain from "./pages/Chain";
import CreateSeries from "./pages/CreateSeries";
import Genres from "./pages/Genres";
import Home from "./pages/Home";
import IndianIcon from "./pages/IndianIcon";
import Live from "./pages/Live";
import Login from "./pages/Login";
import Movies from "./pages/Movies";
import Notification from "./pages/Notification";
import Player from "./pages/Player";
import Poster from "./pages/Poster";
import PurchaseRecords from "./pages/PurchaseRecords";
import RedeemRequest from "./pages/RedeemRequest";
import Rent from "./pages/Rent";
import Series from "./pages/Series";
import SubCategory from "./pages/SubCategory";
import TangentCategory from "./pages/TangentCategory";
import UploadRequest from "./pages/UploadRequest";
import Users from "./pages/Users";

function App() {
  return (
    <ChakraProvider>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/series"
          element={
            <Layout>
              <Series />
            </Layout>
          }
        />
        <Route
          path="/categories"
          element={
            <Layout>
              <Categories />
            </Layout>
          }
        />
        <Route
          path="/sub-categories"
          element={
            <Layout>
              <SubCategory />
            </Layout>
          }
        />
        <Route
          path="/series/create"
          element={
            <Layout>
              <CreateSeries />
            </Layout>
          }
        />
        <Route
          path="/series/update/:id"
          element={
            <Layout>
              <CreateSeries update={true} />
            </Layout>
          }
        />
        <Route
          path="/purchase-records"
          element={
            <Layout>
              <PurchaseRecords />
            </Layout>
          }
        />
        <Route
          path="/chain"
          element={
            <Layout>
              <Chain />
            </Layout>
          }
        />
        <Route
          path="/banner"
          element={
            <Layout>
              <Banner />
            </Layout>
          }
        />
        <Route
          path="/tangent-career-category"
          element={
            <Layout>
              <TangentCategory />
            </Layout>
          }
        />
        <Route
          path="/users"
          element={
            <Layout>
              <Users />
            </Layout>
          }
        />
        <Route
          path="/live"
          element={
            <Layout>
              <Live />
            </Layout>
          }
        />
        <Route
          path="/admins"
          element={
            <Layout>
              <Admin />
            </Layout>
          }
        />
        <Route
          path="/subscribtion"
          element={
            <Layout>
              <AddSubcription />
            </Layout>
          }
        />
        <Route
          path="/movies"
          element={
            <Layout>
              <Movies />
            </Layout>
          }
        />
        <Route
          path="/genres"
          element={
            <Layout>
              <Genres />
            </Layout>
          }
        />
        <Route
          path="/notification"
          element={
            <Layout>
              <Notification />
            </Layout>
          }
        />
        <Route
          path="/rent"
          element={
            <Layout>
              <Rent />
            </Layout>
          }
        />
        <Route
          path="/redeem-request"
          element={
            <Layout>
              <RedeemRequest />
            </Layout>
          }
        />
        <Route
          path="/upload-request"
          element={
            <Layout>
              <UploadRequest />
            </Layout>
          }
        />
        <Route
          path="/poster"
          element={
            <Layout>
              <Poster />
            </Layout>
          }
        />
        <Route
          path="/player"
          element={
            <Layout>
              <Player />
            </Layout>
          }
        />
        <Route
          path="/indian-icon"
          element={
            <Layout>
              <IndianIcon />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
